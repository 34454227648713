import React from 'react';
import { Grid, Typography, Card } from '@mui/material';
import propTypes from 'prop-types';
import { StyledIcon } from '../../styles/styles';

const FacilityDashboardItem = ({ title, numerator, denominator, icon }) => {
  return (
    <Grid container>
      <Card
        sx={{
          backgroundColor: '#FAFAFA',
          boxShadow: 'none',
          width: '100%',
        }}
      >
        <Grid
          container
          flexDirection="column"
          alignItems="space-between"
          sx={{ padding: '4px 16px' }}
        >
          <Typography
            variant="overline"
            sx={{ fontSize: 14 }}
            color="text.secondary"
          >
            {title}
          </Typography>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item sx={{ display: 'inline-flex' }} dir="ltr">
              <Typography variant="h4">{numerator}</Typography>
              <Typography
                variant="overline"
                sx={{ lineHeight: '3.8', letterSpacing: '1px' }}
              >
                /{denominator}
              </Typography>
            </Grid>
            <StyledIcon item>{icon}</StyledIcon>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

FacilityDashboardItem.propTypes = {
  title: propTypes.string.isRequired,
  numerator: propTypes.number.isRequired,
  denominator: propTypes.number.isRequired,
  icon: propTypes.element.isRequired,
};

export default FacilityDashboardItem;
