import React from 'react';
import propTypes from 'prop-types';
import {
  Autocomplete,
  TextField,
  createFilterOptions,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHelperTextStyles } from '../../styles/styles';

const filter = createFilterOptions();

const isEqual = (option, newValue) => {
  return (
    option === newValue ||
    option.value === newValue ||
    option.value === newValue.value
  );
};

const AutocompleteInput = ({
  name,
  label,
  options,
  value,
  allowNewOption,
  onChange,
  groupBy,
  required,
  error,
  getOptionDisabled,
  getOptionLabel,
}) => {
  const { t } = useTranslation();
  const helperTextStyles = useHelperTextStyles();

  return (
    <Grid style={{ margin: '16px 16px 0px 16px' }}>
      <Autocomplete
        getOptionDisabled={getOptionDisabled}
        forcePopupIcon
        autoHighlight
        autoComplete
        selectOnFocus
        clearOnBlur
        groupBy={groupBy}
        freeSolo={allowNewOption}
        name={name}
        options={options}
        value={value || null} // empty string triggers a console warning
        filterOptions={(fieldOptions, params) => {
          const filtered = filter(fieldOptions, params);
          const { inputValue } = params;
          if (
            allowNewOption &&
            inputValue?.length > 0
            // && filtered.length === 0
          ) {
            filtered.push({
              inputValue,
              value: `${t('add')} "${inputValue}"`,
            });
          }
          return filtered;
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return getOptionLabel ? getOptionLabel(option) : option.value;
        }}
        isOptionEqualToValue={isEqual}
        onChange={(event, newValue) => {
          const matchingOption =
            typeof newValue === 'string' &&
            options.find((option) => isEqual(option, newValue));
          if (getOptionDisabled && getOptionDisabled(matchingOption)) {
            return;
          }
          let finalValue = newValue;
          // matching types (options and newValue), so it's easier to use
          const isOptionsString = typeof options[0] === 'string';
          if (newValue?.inputValue) {
            // new value (not in options) shown as `Add "${inputValue}`
            finalValue = isOptionsString
              ? newValue.inputValue
              : {
                  value: newValue.inputValue,
                };
          } else if (typeof newValue === 'string' && !isOptionsString) {
            finalValue = { value: finalValue };
          }
          onChange(event, matchingOption || finalValue);
        }}
        renderInput={(params) => (
          <TextField
            /* eslint-disable-next-line */
            {...params}
            label={label}
            name={name}
            required={required}
            error={error}
            helperText={error ? t('invalidField') : null}
            FormHelperTextProps={{
              classes: {
                root: helperTextStyles.noHorizontalMargin,
              },
            }}
          />
        )}
      />
    </Grid>
  );
};

AutocompleteInput.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  allowNewOption: propTypes.bool,
  groupBy: propTypes.func,
  required: propTypes.bool,
  error: propTypes.bool,
  getOptionDisabled: propTypes.func,
  getOptionLabel: propTypes.func,
  options: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.string),
    propTypes.arrayOf(
      propTypes.shape({
        value: propTypes.string,
      })
    ),
  ]).isRequired,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.shape({
      value: propTypes.string,
    }),
  ]),
};
AutocompleteInput.defaultProps = {
  allowNewOption: false,
  value: null,
  groupBy: null,
  required: false,
  error: false,
  getOptionDisabled: null,
  getOptionLabel: null,
};

export default AutocompleteInput;
