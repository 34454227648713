import { gql } from '@apollo/client';

const GET_NOTES_FOR_DEVICE = gql`
  query GetNotesForDevice($deviceId: String!) {
    getNotesForDevice(deviceId: $deviceId) {
      id
      note
      timestamp
      technician
    }
  }
`;

export default GET_NOTES_FOR_DEVICE;
