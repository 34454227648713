import React from 'react';
import PropTypes from 'prop-types';
import SliderUnstyled from '@mui/base/SliderUnstyled';

export const AudioSlider = ({
  duration,
  position = 0,
  onChangePosition,
  ...props
}) => {
  const value = duration ? (100 * position) / duration : 0;

  const handleChange = (_, newValue) => {
    const pos = duration ? (newValue / 100) * duration : 0;
    onChangePosition({ sliderValue: newValue, audioPosition: pos });
  };

  return (
    <SliderUnstyled
      {...props}
      aria-label="audio-slider"
      value={value}
      onChange={handleChange}
    />
  );
};

AudioSlider.propTypes = {
  duration: PropTypes.number,
  position: PropTypes.number,
  onChangePosition: PropTypes.func,
};
