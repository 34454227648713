import React, { Suspense, useState } from 'react';
import propTypes from 'prop-types';
import { BrowserRouter } from 'react-router-dom';
import { HoneycombThemeProvider } from '@hala-systems/honeycomb';
import { createTheme } from '@mui/material/styles';
import { Toolbar as Spacer } from '@mui/material';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import VwsMonitoringProvider from './VwsMonitoringProvider/VwsMonitoringProvider';
import VwsMonitoringAuthenticator from '../auth/components/VwsMonitoringAuthenticator';
import Layout from './Layout/Layout';
import AppRoutes from './AppRoutes';
import { regular } from '../styles/fonts';
import i18n from './i18n';
import LocaleContext from '../locale/LocaleContext';
import PermissionControl from '../auth/components/PermissionControl';
import SnackbarProvider from '../context/SnackbarProvider';
import StoreProvider from '../context/StoreProvider';

const theme = createTheme({
  palette: {
    primary: {
      main: '#536063',
    },
  },
  typography: {
    fontFamily: regular,
    h6: {
      fontWeight: 600,
    },
    subtitle2: {
      fontWeight: 600,
    },
    button: {
      fontWeight: 600,
    },
  },
});

const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

const cacheLtr = createCache({
  key: 'muiltr',
  stylisPlugins: [prefixer],
});

const AppProviders = ({ children }) => {
  const [locale, setLocale] = useState(i18n.language);
  i18n.on('languageChanged', () => setLocale(i18n.language));

  return (
    // eslint-disable-next-line
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <Suspense fallback="loading...">
        <VwsMonitoringProvider>
          <CacheProvider value={locale === 'ar' ? cacheRtl : cacheLtr}>
            <HoneycombThemeProvider
              theme={{
                ...theme,
                direction: locale === 'ar' ? 'rtl' : 'ltr',
              }}
            >
              <VwsMonitoringAuthenticator>
                <StoreProvider>
                  <BrowserRouter>
                    <PermissionControl>
                      <SnackbarProvider>
                        <Layout direction={locale === 'ar' ? 'rtl' : 'ltr'}>
                          {children}
                        </Layout>
                      </SnackbarProvider>
                    </PermissionControl>
                  </BrowserRouter>
                </StoreProvider>
              </VwsMonitoringAuthenticator>
            </HoneycombThemeProvider>
          </CacheProvider>
        </VwsMonitoringProvider>
      </Suspense>
    </LocaleContext.Provider>
  );
};

const App = () => (
  <AppProviders>
    <Spacer />
    <AppRoutes />
  </AppProviders>
);

AppProviders.propTypes = {
  children: propTypes.node.isRequired,
};

export default App;
