import { gql } from '@apollo/client';

const ADD_NOTE_FOR_DEVICE = gql`
  mutation AddNoteForDevice($deviceId: String!, $note: String!) {
    addNoteForDevice(deviceId: $deviceId, note: $note) {
      id
      note
      timestamp
    }
  }
`;

export default ADD_NOTE_FOR_DEVICE;
