import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TitleSection from '../TitleSection/TitleSection';
import CustomTextInput from '../CustomTextInput/CustomTextInput';
import CustomSelectInput from '../CustomSelectInput/CustomSelectInput';

import { AVAILABLE_DIRECTORATES } from '../../const/const';
import { isValidUrl } from '../../utils/utils';
import AutocompleteInput from '../AutocompleteInput/AutocompleteInput';
import { useStore } from '../../context/StoreProvider';
import { getFacilityFullLocation, getTownLabel } from '../../utils/models';
import AutocompleteVirtualized from '../AutocompleteInput/AutocompleteVirtualized';

const getOptionLabel = (option) => getTownLabel(option);

const FacilityForm = ({ facility, dirtyFields, onChange, addOnly }) => {
  const { t } = useTranslation();
  const { allFacilities, allTowns, townsById } = useStore();
  const selectedTown = townsById[facility.town] ?? '';

  return (
    <>
      <TitleSection title={t('facilityInformation')} />
      <AutocompleteInput
        getOptionDisabled={
          !addOnly
            ? null
            : (option) => {
                // disable existing options
                return Boolean(option?.directorate);
              }
        }
        name="name"
        label={t('facilityName')}
        value={getFacilityFullLocation(facility)}
        required
        error={dirtyFields.name}
        options={allFacilities}
        allowNewOption
        onChange={(e, newValue) => {
          if (!newValue) {
            onChange(e, { name: '' });
            return;
          }
          const newData = newValue.directorate
            ? {
                name: newValue.name,
                town: newValue.town,
                location: newValue.location,
                directorate: newValue.directorate,
                whatsappLink: newValue.whatsappLink,
                townData: newValue.townData,
              }
            : { name: newValue.value };
          onChange(e, newData);
        }}
        groupBy={(facilityItem) => facilityItem.directorate}
      />

      <AutocompleteVirtualized
        name="town"
        label={t('town')}
        value={selectedTown}
        required
        error={dirtyFields.town}
        options={allTowns}
        onChange={(e, newValue) => {
          if (!newValue) {
            onChange(e, { town: '', townData: null });
            return;
          }
          onChange(e, { town: String(newValue.townID), townData: newValue });
        }}
        getOptionLabel={getOptionLabel}
      />

      <CustomSelectInput
        name="directorate"
        label={t('menu.directorate')}
        value={facility.directorate}
        id="facility-directorate"
        options={AVAILABLE_DIRECTORATES.map((directorate) => {
          return { ...directorate, label: t(`menu.${directorate.label}`) };
        })}
        onChange={(e) => {
          onChange(e, { directorate: e.target.value });
        }}
      />

      {/* <CustomSelectInput
        name="location"
        label={t('sector')}
        value={facility.location}
        id="facility-sector"
        options={AVAILABLE_SECTORS.map((sector) => {
          return { ...sector, label: t(`${sector.label}`) };
        })}
        onChange={(e) => {
          onChange(e, { location: e.target.value });
        }}
      /> */}

      <CustomTextInput
        name="whatsappLink"
        label={t('whatsappLink')}
        value={facility.whatsappLink ?? ''}
        type="url"
        onChange={(e) => {
          onChange(e, { whatsappLink: e.target.value });
        }}
        error={
          facility.whatsappLink ? !isValidUrl(facility.whatsappLink) : false
        }
      />
    </>
  );
};

FacilityForm.propTypes = {
  facility: propTypes.shape({
    name: propTypes.string.isRequired,
    location: propTypes.string,
    town: propTypes.string.isRequired,
    directorate: propTypes.string.isRequired,
    whatsappLink: propTypes.string,
    townData: propTypes.shape({
      townID: propTypes.oneOfType([propTypes.string, propTypes.number]),
      pcode: propTypes.string,
      englishName: propTypes.string,
      arabicName: propTypes.string,
    }),
  }).isRequired,
  dirtyFields: propTypes.shape({
    name: propTypes.bool,
    location: propTypes.bool,
    town: propTypes.bool,
  }).isRequired,
  onChange: propTypes.func.isRequired,
  addOnly: propTypes.bool,
};
FacilityForm.defaultProps = {
  addOnly: false,
};

export default FacilityForm;
