import { useQuery, gql } from '@apollo/client';

const GET_SENTRY_RELAYS = gql`
  query GetSentryRelays {
    getSentryRelays {
      id
      serial
      version
      batch
      notes
      activationTimeID
      owner
      operator
      location
      isPublic
    }
  }
`;

const useSentryRelays = () => {
  const { data, loading, error } = useQuery(GET_SENTRY_RELAYS);

  return { loading, error, sentryRelays: data?.getSentryRelays };
};

export default useSentryRelays;
