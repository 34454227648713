import { styled } from '@mui/material/styles';

export const StyledImage = styled('img')(({ theme }) => ({
  cursor: 'pointer',
  height: '100%',
  margin: 0,
  display: 'flex',
  padding: 0,
  overflow: 'hidden',
  minWidth: '100%',
  objectFit: 'cover',
}));
