import { format } from 'date-fns';

/* eslint-disable no-param-reassign */
export const timeToWords = (time, language) => {
  const timespans = [1000, 60000, 3600000, 86400000, 31540000000];
  const parsedTime = Date.parse(time.replace(/-00:?00$/, ''));
  const isArabic = language === 'ar';
  const lang = {
    postfixes: {
      '<': isArabic ? ' منذ' : ' ago',
      '>': isArabic ? ' من الان' : ' from now',
    },
    1000: {
      singular: isArabic ? 'لحظات قليلة' : 'a few moments',
      plural: isArabic ? 'لحظات قليلة' : 'a few moments',
    },
    60000: {
      singular: isArabic ? 'حوالي دقيقة' : 'about a minute',
      plural: isArabic ? '# الدقائق' : '# minutes',
    },
    3600000: {
      singular: isArabic ? 'حوالي ساعة' : 'about an hour',
      plural: isArabic ? '# ساعات' : '# hours',
    },
    86400000: {
      singular: isArabic ? 'يوم' : 'a day',
      plural: isArabic ? '# أيام' : '# days',
    },
    31540000000: {
      singular: isArabic ? 'سنة' : 'a year',
      plural: isArabic ? '# سنوات' : '# years',
    },
  };

  if (parsedTime && Date.now) {
    const timeAgo = parsedTime - Date.now();
    const diff = Math.abs(timeAgo);
    const postfix = lang.postfixes[timeAgo < 0 ? '<' : '>'];
    let timespan = timespans[0];

    for (let i = 1; i < timespans.length; i += 1) {
      if (diff > timespans[i]) {
        timespan = timespans[i];
      }
    }

    const n = Math.round(diff / timespan);

    return (
      lang[timespan][n > 1 ? 'plural' : 'singular'].replace('#', n) + postfix
    );
  }

  return '';
};

export const checkDeviceStatusInactive = (device) =>
  device.status === 'OFFLINE' && device.active;

export const checkDeviceStatusActive = (device) =>
  device.status === 'ONLINE' && device.active;

export const checkDeviceStatusDeactivated = (device) => !device.active;

export const isValidUrl = (string) => {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const isProductionEnv = () => process.env.NODE_ENV === 'production';

export const formatFullTimestamp = (dateString) =>
  dateString ? format(new Date(dateString), 'yyyy-MM-dd HH:mm:ss') : '-';
