import ReactWaves from '@dschoon/react-waves';
import { styled } from '@mui/material/styles';

export const StyledReactWaves = styled(ReactWaves)({
  width: '100%',
  margin: '0',
  padding: '0',
  lineHeight: '0',
  '&.hidden': {
    position: 'absolute',
    visibility: 'hidden',
  },
});
