import {
  DEVICE_ACTIVATION_TIME,
  DEVICE_OPERATOR,
  DEVICE_OWNER,
} from '../../const/const';

export const MAP_TOWN_LABEL_TO_ID = {
  Darkush: '126',
  Idlib: '288',
  'Darat Izza': '2443',
  'Ras al-Hisn': '191',
  'Kafr Naseh al-Atarib': '2472',
  "Ma'rrat Tamasrin": '276',
  Harem: '142',
  'Kafr Takhareem': '182',
  Qurqanya: '198',
  'Kafr Yahmul': '271',
  'Orm al-Joz': '2',
  Ahsim: '28',
  'Ain Laruz': '32',
  'Kherbet al-Joz': '106',
  'Jisr al-Shughour': '75',
  Bdama: '102',
  'Marj al-Zohur': '97',
  'Ain al-Hamra': '78',
  Harbanoush: '272',
  "al-Fu'ah": '235',
  "Tu'oom": '236',
  Atarib: '2458',
};

const ACTIVATION_TIME_MAP_FROM_SENTRY = {
  1: '24 hours',
  2: '12 hours/day',
  3: '12 hours/night',
};

const DEFAULT_DEVICE_DATA = {
  version: 'v2.0',
  batch: 'Batch 2019',
  hasPowerkit: true,
  deviceId: '',
  altDeviceId: null,
  type: '',
  activationTime: DEVICE_ACTIVATION_TIME[0].value,
  owner: DEVICE_OWNER[0].value,
  operator: DEVICE_OPERATOR[0].value,
};

export const getDeviceRawData = (
  device,
  options = { useDefaultValues: false, removeEmptyProperties: true }
) => {
  const { useDefaultValues, removeEmptyProperties } = options;
  let rawData = {
    deviceId: device?.deviceId ?? device?.serial,
    altDeviceId: device?.altDeviceId ?? device?.id,
    version: device?.version,
    batch: device?.batch,
    type: device?.type,
    activationTime:
      device?.activationTime ??
      ACTIVATION_TIME_MAP_FROM_SENTRY[device?.activationTimeID],
    owner: device?.owner,
    operator: device?.operator,
    active:
      device?.active ??
      (Number.isInteger(device?.isPublic) ? device.isPublic === 1 : null),
  };
  if (useDefaultValues) {
    rawData = { ...DEFAULT_DEVICE_DATA, ...rawData };
  }
  if (removeEmptyProperties) {
    Object.keys(rawData).forEach((prop) => {
      if (rawData[prop] === null || rawData[prop] === undefined) {
        delete rawData[prop];
      }
    });
  }
  return rawData;
};

export const getFacilityRawData = (facility) => {
  return {
    name: facility?.name,
    location: facility?.location,
    town: facility?.town,
    directorate: facility?.directorate,
    whatsappLink: facility?.whatsappLink,
    townData: facility?.townData,
  };
};

export default {
  MAP_TOWN_LABEL_TO_ID,
  getDeviceRawData,
  getFacilityRawData,
};

/*
"Darkush"              -> "Darkosh"               (pcode: "C4252", townID: 126)
"Idlib"                -> "Idleb"                 (pcode: "C3871", townID: 288)
"Darat Izza"           -> "Daret Azza"            (pcode: "C1139", townID: 2443)
"Ras al-Hisn"          -> "Ras Elhisn"            (pcode: "C4167", townID: 191)
"Kafr Naseh al-Atarib" -> "Kafr Naseh Elatareb"   (pcode: "C1036", townID: 2472)
"Ma'rrat Tamasrin"     -> "Maarrat Tamasrin"      (pcode: "C3947", townID: 276)
"Harem"                -> "Harim"                 (pcode: "C4115", townID: 142)
"Kafr Takhareem"       -> "Kafr Takharim"         (pcode: "C4157", townID: 182)
"Qurqanya"             -> "Qourqeena"             (pcode: "C4174", townID: 198)
"Kafr Yahmul"          -> "Kafr - Kafrehmul"      (pcode: "C3942", townID: 271)
"Orm al-Joz"           -> "Orm Eljoz"             (pcode: "C4269", townID: 2)
"Ahsim"                -> "Ehsem"                 (pcode: "C4295", townID: 28)
"Ain Laruz"            -> "Ein Laruz"             (pcode: "C4299", townID: 32)
"Kherbet al-Joz"       -> "Kherbet Eljoz"         (pcode: "C4231", townID: 106)
"Jisr al-Shughour"     -> "Jisr-Ash-Shugur"       (pcode: "C4199", townID: 75)
"Bdama"                -> "Badama"                (pcode: "C4226", townID: 102)
"Marj al-Zohur"        -> "Marj Elzohur"          (pcode: "C4221", townID: 97)
"Ain al-Hamra"         -> "Ein Elhamra"           (pcode: "C4202", townID: 78)
"Harbanoush"           -> "Haranbush"             (pcode: "C3943", townID: 272)
"al-Fu'ah"             -> "Foah"                  (pcode: "C3905", townID: 235)
"Tu'oom"               -> "Toum"                  (pcode: "C3906", townID: 236)
"Atarib"               -> "Atareb"                (pcode: "C1022", townID: 2458)
*/
