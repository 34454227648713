import React from 'react';
import propTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

export const StyledTableCell = styled(TableCell)((props) => ({
  fontWeight: props.bold ? 700 : 400,
  borderBottom: 0,
}));

const RelayDetailsTable = ({ relay }) => {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table aria-label="relay details table">
        <TableBody>
          {Object.entries(relay).map((entry) => (
            <TableRow key={entry[0]}>
              <StyledTableCell bold="true">{t(entry[0])}</StyledTableCell>
              <StyledTableCell>{entry[1]}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

RelayDetailsTable.propTypes = {
  relay: propTypes.shape({
    status: propTypes.string.isRequired,
    'serial number': propTypes.string.isRequired,
    location: propTypes.string,
    town: propTypes.string.isRequired,
    'last active': propTypes.string.isRequired,
    'last activation': propTypes.string.isRequired,
    version: propTypes.string,
    batch: propTypes.string,
    hasPowerkit: propTypes.bool,
  }).isRequired,
};

export default RelayDetailsTable;
