import { gql } from '@apollo/client';

const SAVE_DEVICE = gql`
  mutation SaveDevice(
    $device: DeviceData!
    $facility: FacilityData
    $saveToSentry: Boolean
  ) {
    saveDevice(
      device: $device
      facility: $facility
      saveToSentry: $saveToSentry
    ) {
      id
      name
      location
      town
      whatsappLink
      townData {
        townID
        pcode
        englishName
        arabicName
      }
      devices {
        id
        type
        deviceId
        altDeviceId
        status
        onlineConfirmationTimestamp
        active
        version
        batch
        hasPowerkit
        owner
        operator
        activationTime
      }
    }
  }
`;

export default SAVE_DEVICE;
