import { alpha } from '@mui/system';
import { styled } from '@mui/material/styles';
import { AudioSlider } from './AudioSlider';

export const StyledAudioSlider = styled(AudioSlider)(({ theme }) => {
  const color = theme.palette.primary.main;
  const sliderHeight = '2px';
  const thumbRadius = '12px';

  return `
    color: ${color};
    height: ${sliderHeight};
    width: 100%;
    padding: 5px 0;
    display: inline-block;
    position: relative;
    cursor: pointer;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;
    opacity: 0.75;
    &:hover {
      opacity: 1;
    }

    & .MuiSlider-rail {
      display: block;
      position: absolute;
      width: 100%;
      height: ${sliderHeight};
      border-radius: 2px;
      background-color: currentColor;
      opacity: 0.38;
    }

    & .MuiSlider-track {
      display: block;
      position: absolute;
      height: ${sliderHeight};
      border-radius: 2px;
      background-color: currentColor;
    }

    & .MuiSlider-thumb {
      position: absolute;
      width: ${thumbRadius};
      height: ${thumbRadius};
      margin-left: -6px;
      margin-top: -5px;
      box-sizing: border-box;
      border-radius: 50%;
      outline: 0;
      border: 2px solid currentColor;
      background-color: ${color};

      :hover,
      &.Mui-focusVisible {
        box-shadow: 0 0 0 0.25rem ${alpha(color, 0.15)};
      }

      &.Mui-active {
        box-shadow: 0 0 0 0.25rem ${alpha(color, 0.3)};
      }
    }
  `;
});
