import React, { useRef, useState } from 'react';
import ConfirmDialog from '../components/ConfirmDialog/ConfirmDialog';

const useConfirmDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [_title, setTitle] = useState('');
  const [_text, setText] = useState('');
  const [_confirmLabel, setConfirmLabel] = useState('');
  const callbackRef = useRef();

  const showDialog = ({ title, text, callback, confirmLabel }) => {
    callbackRef.current = callback;
    setTitle(title);
    setText(text);
    setIsOpen(true);
    setConfirmLabel(confirmLabel);
  };
  const hideDialog = () => setIsOpen(false);

  const confirmDialog = (
    <ConfirmDialog
      open={isOpen}
      onClick={(isConfirmed) => {
        hideDialog();
        if (callbackRef.current) {
          callbackRef.current(isConfirmed);
        }
      }}
      title={_title}
      text={_text}
      confirmLabel={_confirmLabel}
    />
  );

  return [
    confirmDialog,
    {
      showDialog,
      hideDialog,
    },
  ];
};

export default useConfirmDialog;
