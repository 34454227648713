import { gql } from '@apollo/client';

const GET_DEVICES_BY_DIRECTORATE = gql`
  query GetDevicesByDirectorate($directorate: String!) {
    getDevicesByDirectorate(directorate: $directorate) {
      id
      name
      location
      town
      whatsappLink
      townData {
        townID
        pcode
        englishName
        arabicName
      }
      devices {
        id
        type
        deviceId
        altDeviceId
        status
        onlineConfirmationTimestamp
        active
        version
        batch
        hasPowerkit
        owner
        operator
        activationTime
        lastActivationTimestamp
      }
    }
  }
`;

export default GET_DEVICES_BY_DIRECTORATE;
