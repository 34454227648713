import { gql } from '@apollo/client';

const FACILITIES_BY_DIRECTORATE = gql`
  query allFacilitiesByDirectorateId($id: String!) {
    facilities {
      id
      name
      location
      whatsappLink
      devices
    }
  }
`;

export default FACILITIES_BY_DIRECTORATE;
