import { gql } from '@apollo/client';

const GET_TOWNS = gql`
  query GetTowns($searchTerm: String) {
    getTowns(searchTerm: $searchTerm) {
      townID
      pcode
      englishName
      arabicName
    }
  }
`;

export default GET_TOWNS;
