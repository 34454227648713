import { useQuery } from '@apollo/client';
import GET_NOTES_FOR_DEVICE from '../queries/getNotesForDevice';

const useGetNotesForDevice = (deviceId) => {
  const { data, loading, error } = useQuery(GET_NOTES_FOR_DEVICE, {
    variables: {
      deviceId,
    },
  });
  return { loading, error, notes: data?.getNotesForDevice };
};

export default useGetNotesForDevice;
