import React from 'react';
import propTypes from 'prop-types';
import { useAuthUser } from '@hala-systems/hala-auth';
import { Button, Box, Typography } from '@mui/material';
import { Auth, Hub } from 'aws-amplify';
import { userHasPermission } from '../permissions';

// import { LogoutButton } from '../../components';

const logout = async () => {
  await Auth.signOut({ global: true });
  Hub.dispatch('UI Auth', {
    event: 'AuthStateChange',
    message: 'signedout',
  });
};

const NotAuthorizedMessage = () => (
  <Box
    sx={{
      p: 6,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    }}
  >
    <Typography variant="h6" component="h6" gutterBottom>
      Unauthorized
    </Typography>

    <Typography variant="body1" component="p" gutterBottom>
      Sorry, you don&apos;t currently have access to this page.
    </Typography>

    <Typography variant="body1" component="p" gutterBottom>
      Please reach out to{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="mailto:nebojsa@halasystems.com?subject=Access to User Management App &body=Please give this email access to the overlay app"
      >
        nebojsa@halasystems.com
      </a>{' '}
      to get access
    </Typography>

    <Box>
      <Button
        size="large"
        color="error"
        onClick={logout}
        variant="outlined"
        role="button"
        aria-label="sign out"
      >
        Sign out
      </Button>
    </Box>
  </Box>
);

const PermissionControl = ({ children }) => {
  const { user } = useAuthUser();

  const hasPermission = userHasPermission(user, 'ACCESS_VWS_MON');

  return hasPermission ? children : <NotAuthorizedMessage />;
};

PermissionControl.propTypes = {
  children: propTypes.node.isRequired,
};

export default PermissionControl;
