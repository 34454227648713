import React from 'react';
import propTypes from 'prop-types';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';

import { Button, Grid, Typography } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import WarningIcon from '@mui/icons-material/Warning';
import { useTranslation } from 'react-i18next';
import usePermissions from '../../hooks/usePermissions';
import { ContactButton, TitleSection } from '../../components';
import DevicesList from './DevicesList';
import {
  checkDeviceStatusActive,
  checkDeviceStatusDeactivated,
  checkDeviceStatusInactive,
  isProductionEnv,
} from '../../utils/utils';
import { getTownLabel } from '../../utils/models';
import { useStore } from '../../context/StoreProvider';
import useTestActivation from '../../hooks/useTestActivation';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import useResponseSnackbar from '../../hooks/useResponseSnackbar';

const FacilityDetailHeader = ({ location }) => (
  <Grid container sx={{ padding: '16px' }} justifyContent="space-between">
    <Typography variant="b1" color="GrayText">
      {location}
    </Typography>
    <PlaceIcon sx={{ color: '#9e9e9e' }} />
  </Grid>
);

const NoDevicesFound = () => {
  const { t } = useTranslation();
  return (
    <Grid item padding={3}>
      {t('noDevicesFound')}
    </Grid>
  );
};

const FacilityDetailsPage = () => {
  const { t } = useTranslation();
  const { isAdmin } = usePermissions();
  const location = useLocation();
  const navigate = useNavigate();
  const { townsById } = useStore();

  const { runTestActivation } = useTestActivation();
  const [confirmDialog, { showDialog }] = useConfirmDialog();
  const { showError, showSuccess } = useResponseSnackbar();

  if (!location.state) {
    return <Navigate to="/" />;
  }
  const { facility } = location.state;
  const { devices } = facility;

  const deviceIds = devices.map((device) => device.altDeviceId ?? device.id);

  const devicesThatNeedAttention = devices.filter(checkDeviceStatusInactive);
  const devicesDeactivated = devices.filter(checkDeviceStatusDeactivated);
  const devicesActive = devices.filter(checkDeviceStatusActive);

  const showEditPage = () => {
    navigate('/edit-facility', {
      state: { facility, deviceIds },
    });
  };

  const showRelayAddPage = () => {
    navigate('/add-relay', {
      state: { facility },
    });
  };

  const getTownName = () => {
    return townsById && townsById[facility.town]
      ? getTownLabel(townsById[facility.town])
      : getTownLabel(facility.town);
  };

  const activateAllRelaysWarning = (isConfirmed) => {
    if (!isConfirmed) return;
    Promise.allSettled(
      deviceIds.map((deviceId) => runTestActivation({ deviceId }))
    ).then((promises) => {
      const rejectedPromises = promises.filter(
        (promise) => promise.status === 'rejected'
      );
      if (rejectedPromises.length > 0) {
        showError(
          <>
            <div>{`${rejectedPromises.length}/${deviceIds.length} warning(s) failed to activate:`}</div>
            {promises.map(
              (promise, index) =>
                promise.status === 'rejected' && (
                  <div key={deviceIds[index]}>- {deviceIds[index]}</div>
                )
            )}
          </>
        );
      } else {
        showSuccess('All relays warning activated');
      }
    });
  };

  return (
    <Grid>
      <FacilityDetailHeader location={getTownName()} />

      {devices.length === 0 ? <NoDevicesFound /> : null}

      {devicesThatNeedAttention?.length > 0 ? (
        <>
          <TitleSection
            title={t('needsAttentionDetails')}
            number={devicesThatNeedAttention.length}
          />
          <DevicesList devices={devicesThatNeedAttention} facility={facility} />
        </>
      ) : null}

      {devicesActive?.length > 0 ? (
        <>
          <TitleSection
            title={t('activeRelays')}
            number={devicesActive.length}
          />
          <DevicesList devices={devicesActive} facility={facility} />
        </>
      ) : null}

      {devicesDeactivated?.length > 0 ? (
        <>
          <TitleSection
            title={t('deactivedRelays')}
            number={devicesDeactivated.length}
          />
          <DevicesList devices={devicesDeactivated} facility={facility} />
        </>
      ) : null}

      <TitleSection
        title={t('tools')}
        showNewFeature={Date.now() <= new Date('2022-12-31 23:59:59')}
      />
      <Grid item style={{ padding: '16px 16px 0px 16px' }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<WarningIcon />}
          disabled={!isProductionEnv()}
          onClick={() => {
            showDialog({
              title: 'Activate warning system for all relays',
              text: `Are you sure you want to activate warning for ALL ${deviceIds.length} relays in this facility?`,
              callback: activateAllRelaysWarning,
              confirmLabel: 'Yes, activate warning for all',
            });
          }}
        >
          Activate warning for all relays
        </Button>
      </Grid>

      {isAdmin() && (
        <>
          <Grid item style={{ padding: '16px 16px 0px 16px' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddCircleIcon />}
              onClick={showRelayAddPage}
            >
              {t('addRelay')}
            </Button>
          </Grid>

          <Grid item style={{ padding: '16px 16px 0px 16px' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<EditIcon />}
              onClick={showEditPage}
            >
              {t('editFacility')}
            </Button>
          </Grid>
        </>
      )}

      <ContactButton facilityUrl={facility.whatsappLink} />
      {confirmDialog}
    </Grid>
  );
};

FacilityDetailHeader.propTypes = {
  location: propTypes.string,
};
FacilityDetailHeader.defaultProps = {
  location: '',
};

export default FacilityDetailsPage;
