import { gql } from '@apollo/client';

const UPDATE_DEVICES_LOCATION = gql`
  mutation UpdateDevicesLocation(
    $deviceIds: [String]!
    $facility: FacilityData!
  ) {
    updateDevicesLocation(deviceIds: $deviceIds, facility: $facility) {
      id
      name
      location
      town
      whatsappLink
      townData {
        townID
        pcode
        englishName
        arabicName
      }
      devices {
        id
        type
        deviceId
        status
        onlineConfirmationTimestamp
        active
        version
        batch
        hasPowerkit
        owner
        operator
        activationTime
      }
    }
  }
`;

export default UPDATE_DEVICES_LOCATION;
