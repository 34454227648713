import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import SET_DEVICE_ACTIVE_STATUS from '../mutations/setDeviceActiveStatus';
import GET_DEVICES_BY_DIRECTORATE from '../queries/getDevicesByDirectorate';
import { SearchFacilityContext } from '../context/SearchFacilitiesContextProvider';

const useSetDeviceActiveStatus = () => {
  const searchContext = useContext(SearchFacilityContext);
  const [runSetDeviceActiveStatus, { loading, error, data }] = useMutation(
    SET_DEVICE_ACTIVE_STATUS
  );
  const setDeviceActiveStatus = ({ deviceId, isActive }) => {
    return runSetDeviceActiveStatus({
      variables: {
        deviceId,
        isActive,
      },
      refetchQueries: () => [
        {
          query: GET_DEVICES_BY_DIRECTORATE,
          variables: {
            directorate: searchContext.directorate,
          },
        },
      ],
    });
  };
  return { setDeviceActiveStatus, response: { loading, error, data } };
};

export default useSetDeviceActiveStatus;
