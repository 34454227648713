import React, { useState, createContext, useContext, useCallback } from 'react';
import propTypes from 'prop-types';
import { IconButton, Snackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// eslint-disable-next-line
import _ from 'lodash';

export const SnackbarContext = createContext({
  isOpen: false,
  // eslint-disable-next-line
  showSnackbar: (message, dismissable = false, options = {}) => undefined,
  hideSnackbar: _.noop,
});

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

const SnackbarContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDismissable, setDismissable] = useState(false);
  const [snackbarMessage, setMessage] = useState('');
  const [snackbarOptions, setSnackbarOptions] = useState({});

  const showSnackbar = useCallback(
    (message, dismissable = false, options = {}) => {
      setMessage(message);
      setDismissable(dismissable);
      setSnackbarOptions(options);
      setIsOpen(true);
    },
    []
  );
  const hideSnackbar = useCallback(() => {
    setMessage('');
    setDismissable(false);
    setSnackbarOptions({});
    setIsOpen(false);
  }, []);

  const dismissAction = isDismissable ? (
    <IconButton aria-label="close" color="inherit" onClick={hideSnackbar}>
      <CloseIcon />
    </IconButton>
  ) : null;

  return (
    <SnackbarContext.Provider
      // eslint-disable-next-line
      value={{
        isOpen,
        showSnackbar,
        hideSnackbar,
      }}
    >
      {children}
      <Snackbar
        action={dismissAction}
        key={new Date().getTime()}
        // eslint-disable-next-line
        {...snackbarOptions}
        message={snackbarMessage}
        open={!!snackbarMessage && isOpen}
      />
    </SnackbarContext.Provider>
  );
};

SnackbarContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default SnackbarContextProvider;
