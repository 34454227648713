import { useAuthUser } from '@hala-systems/hala-auth';
import { userHasPermission } from '../auth/permissions';

const usePermissions = () => {
  const { user } = useAuthUser();

  return {
    isAdmin: () => userHasPermission(user, 'VWS_MON_ADMIN'),
    hasPermission: (permission) => userHasPermission(user, permission),
  };
};

export default usePermissions;
