import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { SearchFacilityContext } from '../context/SearchFacilitiesContextProvider';
import UPDATE_DEVICES_LOCATION from '../mutations/updateDevicesLocation';
import GET_DEVICES_BY_DIRECTORATE from '../queries/getDevicesByDirectorate';
import { isProductionEnv } from '../utils/utils';

const useUpdateDevicesLocation = () => {
  const { directorate } = useContext(SearchFacilityContext);
  const [updateDevicesLocation, { loading, error, data }] = useMutation(
    UPDATE_DEVICES_LOCATION
  );
  const runUpdateDevicesLocation = ({
    deviceIds,
    facility,
    onlyForExperiments = false,
  }) => {
    const { townData, ...facilityData } = facility;

    const refetchQueries = [
      {
        query: GET_DEVICES_BY_DIRECTORATE,
        variables: {
          directorate: facility.directorate,
        },
      },
    ];
    if (directorate && directorate !== facility.directorate) {
      refetchQueries.push({
        query: GET_DEVICES_BY_DIRECTORATE,
        variables: {
          directorate,
        },
      });
    }

    return updateDevicesLocation({
      variables: {
        deviceIds,
        facility: facilityData,
        saveToSentry: onlyForExperiments ? false : isProductionEnv(),
      },
      refetchQueries,
    });
  };
  return { runUpdateDevicesLocation, response: { loading, error, data } };
};

export default useUpdateDevicesLocation;
