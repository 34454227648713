import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Box } from '@mui/material';
import Sidebar from './Sidebar';
import TopToolBar from './TopToolBar';
import useConnectionSnackbar from '../../hooks/useConnectionSnackbar';

const mainStyle = { flexGrow: 1, p: 0 };
const appStyle = { display: 'flex' };

const Layout = ({ children, direction }) => {
  const [openMenu, setOpenMenu] = useState(false);
  useConnectionSnackbar();

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  return (
    <Box dir={direction} sx={{ overflow: 'hidden' }}>
      <Box sx={appStyle}>
        <TopToolBar onMenuClick={() => toggleMenu()} />
        <Sidebar open={openMenu} onClose={() => toggleMenu()} />
      </Box>
      <Box component="main" sx={mainStyle}>
        {children}
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  children: propTypes.node.isRequired,
  direction: propTypes.string.isRequired,
};

export default Layout;
