import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  FacilitiesPage,
  FacilityDetailsPage,
  FacilityEditPage,
  RelayDetailsPage,
  RelayAddPage,
  AddFacilityRelayPage,
  ScriptsPage,
} from '../pages';

const AppRoutes = () => (
  <Routes>
    <Route exact path="/" element={<FacilitiesPage />} />
    <Route path="/facility-details" element={<FacilityDetailsPage />} />
    <Route path="/edit-facility" element={<FacilityEditPage />} />
    <Route path="/relay-details" element={<RelayDetailsPage />} />
    <Route path="/add-relay" element={<RelayAddPage />} />
    <Route path="/edit-relay" element={<RelayAddPage edit />} />
    <Route path="/add-facility-relay" element={<AddFacilityRelayPage />} />
    <Route path="/scripts" element={<ScriptsPage />} />
  </Routes>
);

export default AppRoutes;
