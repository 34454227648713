import { useEffect } from 'react';

const isReachable = () => {
  return (
    // Testing actual internet connectivity, even if device is connected
    fetch('/', { method: 'HEAD', mode: 'no-cors' })
      .then((resp) => {
        return !!resp && (resp.ok || resp.type === 'opaque');
      })
      // eslint-disable-next-line lodash/prefer-constant
      .catch(() => false)
  );
};

const useConnectionListener = (handleConnection) => {
  useEffect(() => {
    const onConnectionChange = () => {
      if (typeof handleConnection !== 'function') {
        return;
      }
      if (navigator.onLine) {
        isReachable().then(handleConnection);
      } else {
        handleConnection(false);
      }
    };
    window.addEventListener('online', onConnectionChange);
    window.addEventListener('offline', onConnectionChange);
    return () => {
      window.removeEventListener('online', onConnectionChange);
      window.removeEventListener('offline', onConnectionChange);
    };
  }, [handleConnection]);
};

export default useConnectionListener;
