import React from 'react';
import propTypes from 'prop-types';
import { Grid, FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import { useTranslation } from 'react-i18next';
import CustomSelectInput from '../CustomSelectInput/CustomSelectInput';
import CustomTextInput from '../CustomTextInput/CustomTextInput';
import TitleSection from '../TitleSection/TitleSection';

import {
  DEVICE_VERSION,
  DEVICE_BATCH,
  DEVICE_TYPE,
  DEVICE_OWNER,
  DEVICE_OPERATOR,
  DEVICE_ACTIVATION_TIME,
} from '../../const/const';
import AutocompleteInput from '../AutocompleteInput/AutocompleteInput';
import { useStore } from '../../context/StoreProvider';

const RelayForm = ({
  relay,
  showTitle,
  dirtyFields,
  onFieldChange,
  isEdit,
}) => {
  const { t } = useTranslation();
  const { allDevices } = useStore();
  return (
    <>
      {showTitle && <TitleSection title={t('relayInformation')} />}
      {isEdit ? (
        <CustomTextInput
          name="deviceId"
          label={t('serial number')}
          value={relay.deviceId}
          // eslint-disable-next-line
          onChange={() => console.log('no change')}
          disabled
        />
      ) : (
        <AutocompleteInput
          name="deviceId"
          label={t('serial number')}
          value={relay.deviceId}
          required
          error={dirtyFields.deviceId}
          options={allDevices}
          allowNewOption
          onChange={(e, newValue) => {
            if (!newValue) {
              onFieldChange(e, { deviceId: null });
              return;
            }
            const newData = {
              deviceId: newValue.value,
              ...(newValue.altDeviceId
                ? { altDeviceId: newValue.altDeviceId }
                : {}),
              ...(newValue.type ? { type: newValue.type } : {}),
              ...(typeof newValue.hasPowerkit === 'boolean'
                ? { hasPowerkit: newValue.hasPowerkit }
                : {}),
              ...(newValue.version ? { version: newValue.version } : {}),
              ...(newValue.batch ? { batch: newValue.batch } : {}),
              ...(newValue.activationTime
                ? { activationTime: newValue.activationTime }
                : {}),
              ...(newValue.owner ? { owner: newValue.owner } : {}),
              ...(newValue.operator ? { operator: newValue.operator } : {}),
            };
            onFieldChange(e, newData);
          }}
          groupBy={(device) => device.facility?.value}
        />
      )}

      <CustomSelectInput
        name="type"
        label={t('relayAlarmType')}
        value={relay.type}
        id="device-type"
        options={DEVICE_TYPE}
        onChange={(e) => {
          onFieldChange(e, { type: e.target.value });
        }}
        error={dirtyFields.type}
        required
      />

      <Grid item style={{ padding: '16px 16px 0px 0px' }}>
        <FormControlLabel
          control={
            <Switch
              name="hasPowerkit"
              checked={relay.hasPowerkit}
              onChange={(e) => {
                onFieldChange(e, { hasPowerkit: e.target.checked });
              }}
            />
          }
          label={t('relayPowerKit')}
          labelPlacement="start"
        />
      </Grid>

      <CustomSelectInput
        name="version"
        label={t('relayVersion')}
        value={relay.version}
        id="device-version"
        options={DEVICE_VERSION}
        onChange={(e) => onFieldChange(e, { version: e.target.value })}
        disabled={!relay.version}
        required
      />

      <CustomSelectInput
        name="batch"
        label={t('relayBatch')}
        value={relay.batch}
        id="device-batch"
        options={DEVICE_BATCH}
        onChange={(e) => onFieldChange(e, { batch: e.target.value })}
        disabled={!relay.batch}
        required
      />

      <CustomSelectInput
        name="activationTime"
        label={t('activationTime')}
        value={relay.activationTime}
        id="device-activation-time"
        options={DEVICE_ACTIVATION_TIME}
        onChange={(e) => onFieldChange(e, { activationTime: e.target.value })}
        required
      />

      <CustomSelectInput
        name="owner"
        label={t('owner')}
        value={relay.owner}
        id="device-activation-time"
        options={DEVICE_OWNER}
        onChange={(e) => onFieldChange(e, { owner: e.target.value })}
        required
      />

      <AutocompleteInput
        name="operator"
        label={`${t('operator')}`}
        value={relay.operator}
        required
        error={dirtyFields.operator}
        options={DEVICE_OPERATOR}
        allowNewOption
        onChange={(e, newValue) => {
          if (!newValue) {
            onFieldChange(e, { operator: null });
            return;
          }
          const newData = {
            operator: newValue.value,
          };
          onFieldChange(e, newData);
        }}
      />
    </>
  );
};

RelayForm.propTypes = {
  relay: propTypes.shape({
    version: propTypes.string.isRequired,
    batch: propTypes.string.isRequired,
    hasPowerkit: propTypes.bool.isRequired,
    deviceId: propTypes.string,
    type: propTypes.string,
    activationTime: propTypes.string,
    owner: propTypes.string,
    operator: propTypes.string,
  }).isRequired,
  showTitle: propTypes.bool,
  dirtyFields: propTypes.shape({
    deviceId: propTypes.bool,
    type: propTypes.bool,
    operator: propTypes.bool,
  }).isRequired,
  onFieldChange: propTypes.func.isRequired,
  isEdit: propTypes.bool,
  // onSwitchChange: propTypes.func.isRequired,
};

RelayForm.defaultProps = {
  showTitle: false,
  isEdit: false,
};

export default RelayForm;
