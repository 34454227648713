import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import {
  Grid,
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
} from '@mui/material';

const CustomSelectInput = ({
  name,
  label,
  value,
  id,
  options,
  error,
  onChange,
  disabled,
  required,
}) => {
  const { t } = useTranslation();
  return (
    <Grid item style={{ padding: '16px 16px 0px 16px' }}>
      <FormControl fullWidth error={error}>
        {label && (
          <InputLabel id={`${id}-label`}>
            {label}
            {required && '*'}
          </InputLabel>
        )}
        <Select
          labelId={`${id}-label`}
          id={id}
          value={value}
          onChange={onChange}
          label={label}
          name={name}
          disabled={disabled}
        >
          {options.map((item) => (
            <MenuItem key={uuidv4()} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {error && (
          <FormHelperText style={{ marginLeft: '0px', marginRight: '0px' }}>
            {t('invalidField')}
          </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

CustomSelectInput.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.string,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  id: propTypes.string.isRequired,
  options: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.oneOfType([propTypes.string, propTypes.number])
        .isRequired,
      label: propTypes.oneOfType([propTypes.string, propTypes.number])
        .isRequired,
    })
  ),
  error: propTypes.bool,
  onChange: propTypes.func.isRequired,
  disabled: propTypes.bool,
  required: propTypes.bool,
};

CustomSelectInput.defaultProps = {
  label: null,
  options: [],
  error: false,
  disabled: false,
  required: false,
};

export default CustomSelectInput;
