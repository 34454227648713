import React, { Suspense, lazy } from 'react';
import propTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import apolloClient from '../../services/apollo';

const isMocked = process.env.REACT_APP_MOCKED === 'true';

const VwsMonitoringProvider = ({ children }) => {
  if (isMocked) {
    const LazyProvider = lazy(() =>
      import('../../mocks/MockedVwsMonitoringProvider')
    );
    return (
      <Suspense fallback={<p>loading...</p>}>
        <LazyProvider>{children}</LazyProvider>
      </Suspense>
    );
  }

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

VwsMonitoringProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default VwsMonitoringProvider;
