import { styled } from '@mui/material/styles';
import { Chip, Divider, ListItem, Grid, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useHelperTextStyles = makeStyles(() => ({
  noHorizontalMargin: {
    marginLeft: '0px',
    marginRight: '0px',
  },
}));

export const StyledDivider = styled(Divider)(({ margin = 16 }) => ({
  '&:last-child': {
    display: 'none',
  },
  marginLeft: margin,
}));

export const StyledChip = styled(Chip)((props) => ({
  fontWeight: 600,
  fontSize: 20,
  height: 40,
  width: 40,
  borderRadius: 20,
  backgroundColor: props.background,
  color: props.font,
}));

export const StyledChipSm = styled(Chip)(() => ({
  fontWeight: 600,
  fontSize: 15,
  height: 32,
  width: 32,
  borderRadius: 16,
  color: '#757575',
}));

export const StyledListItem = styled(ListItem)(() => ({
  padding: '16px',
}));

export const StyledIcon = styled(Grid)(() => ({
  background: 'white',
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  borderRadius: '50%',
  padding: '8px',
  height: '32px',
  width: '32px',
  color: 'rgba(0, 0, 0, 0.6)',
  marginTop: '-12px',
}));

export const StyledDrawerButton = styled(Button)(() => ({
  textTransform: 'none',
  width: '100%',
  justifyContent: 'flex-start',
  borderRadius: 0,
}));

export const StyledDrawerDirectionButton = styled(Button)(() => ({
  textTransform: 'none',
  width: '100%',
  justifyContent: 'flex-start',
  borderRadius: 0,
  '& .MuiButton-startIcon': {
    margin: 0,
    paddingLeft: 8,
  },
}));

export const fabStyle = {
  position: 'fixed',
  bottom: 16,
  right: 16,
  top: 'auto',
  left: 'auto',
};
