import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import propTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Menu, MenuItem, Grid, List, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import Apartment from '@mui/icons-material/Apartment';
import Highlight from '@mui/icons-material/Highlight';
import SortIcon from '@mui/icons-material/Sort';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';

import usePermissions from '../../hooks/usePermissions';
import TitleSection from '../../components/TitleSection/TitleSection';
import FacilitiesDashboardItem from '../../components/FacilitiesDashboardItem/FacilitiesDashboardItem';
import NoFacilitiesFound from '../../components/NoFacilitiesFound/NoFacilitiesFound';
import DevicesNeedAttention from './DevicesNeedAttention';
import AddFacilityButton from './AddFacilityButton';
import { StyledDivider, StyledListItem } from '../../styles/styles';
import LocaleContext from '../../locale/LocaleContext';
import { checkDeviceStatusInactive } from '../../utils/utils';
import { getTownLabel } from '../../utils/models';

const MutedText = ({ text }) => (
  <Typography
    component="p"
    variant="subtitle2"
    sx={{ color: 'text.secondary' }}
  >
    {text}
  </Typography>
);

const DaysInactive = ({ text }) => (
  <Typography
    component="p"
    variant="caption"
    textAlign="right"
    sx={{ color: 'text.secondary' }}
    mb={2}
  >
    {text}
  </Typography>
);

const FacilityListItemInformation = ({ name, location, needsAttention }) => (
  <Grid item xs={9}>
    <Typography component="p" variant="body1">
      {name}
    </Typography>
    <MutedText text={location} />

    {needsAttention.length > 0 ? (
      <DevicesNeedAttention devices={needsAttention} />
    ) : null}
  </Grid>
);

const FacilityListItemIcons = ({ offlineLabel }) => {
  const { locale } = useContext(LocaleContext);
  return (
    <Grid item xs={3}>
      {offlineLabel !== null ? <DaysInactive text={offlineLabel} /> : null}
      <Typography component="p" variant="caption" textAlign="right">
        {locale === 'ar' ? (
          <KeyboardArrowLeftIcon fontSize="small" color="disabled" />
        ) : (
          <ChevronRightIcon fontSize="small" color="disabled" />
        )}
      </Typography>
    </Grid>
  );
};

const FacilityItem = ({ facility }) => {
  const navigate = useNavigate();
  const { devices } = facility;
  const devicesThatNeedAttention = devices.filter(checkDeviceStatusInactive);

  const showFacilityDetails = (facilityData) => {
    navigate('/facility-details', {
      state: { facility: facilityData },
    });
  };

  return (
    <StyledListItem button onClick={() => showFacilityDetails(facility)}>
      <Grid container>
        <FacilityListItemInformation
          name={facility.name}
          location={getTownLabel(facility.townData)}
          needsAttention={devicesThatNeedAttention}
        />
        <FacilityListItemIcons
          offlineLabel={
            devicesThatNeedAttention.length ? facility.offlineLabel : null
          }
        />
      </Grid>
    </StyledListItem>
  );
};

const FacilitiesList = ({ facilities }) => (
  <List>
    {facilities.map((facility) => (
      <React.Fragment key={uuidv4()}>
        <FacilityItem facility={facility} />
        <StyledDivider />
      </React.Fragment>
    ))}
  </List>
);

const FacilitiesDashboard = ({
  inactiveRelays,
  totalRelays,
  allFacilities,
  facilitiesNeedsAttention,
}) => {
  const { t } = useTranslation();

  return (
    <Grid
      padding={2}
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={6} style={{ flexGrow: 1 }}>
        <FacilitiesDashboardItem
          title={t('inactiveRelays')}
          icon={<Highlight />}
          numerator={inactiveRelays}
          denominator={totalRelays}
        />
      </Grid>
      <Grid item xs={6} style={{ flexGrow: 1 }}>
        <FacilitiesDashboardItem
          title={t('facilitiesInNeed')}
          numerator={facilitiesNeedsAttention.length}
          denominator={allFacilities.length}
          icon={<Apartment />}
        />
      </Grid>
    </Grid>
  );
};

const SortingInNeed = ({ order, changeOrder }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (newOrder) => {
    changeOrder(newOrder);
    setAnchorEl(null);
  };

  return (
    <Grid
      sx={{ ml: 'auto', mr: '16px', display: 'flex', alignItems: 'center' }}
    >
      <Typography
        variant="body2"
        fontWeight={400}
        sx={{ color: 'text.secondary', mr: '15px' }}
        fontSize={12}
      >
        {t('sortInNeed')}
      </Typography>
      <SortIcon
        sx={{ color: '#737373' }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(order)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{ sx: { width: '206px' } }}
        sx={{ padding: '16px 6px 16px 6px ' }}
      >
        <MenuItem onClick={() => handleClose('days')}>
          <Grid container>
            <Grid item xs={2} pr="3px">
              {order === 'days' ? (
                <CheckIcon sx={{ color: '#9e9e9e' }} />
              ) : null}
            </Grid>
            <Grid item xs={10}>
              {t('byNumberOfDays')}
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={() => handleClose('relays')}>
          <Grid container>
            <Grid item xs={2}>
              {order === 'relays' ? (
                <CheckIcon sx={{ color: '#9e9e9e' }} />
              ) : null}
            </Grid>
            <Grid item xs={10}>
              {t('byNumberOfRelays')}
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem onClick={() => handleClose('alpha')}>
          <Grid container>
            <Grid item xs={2}>
              {order === 'alpha' ? (
                <CheckIcon sx={{ color: '#9e9e9e' }} />
              ) : null}
            </Grid>
            <Grid item xs={10}>
              {t('byAlphabetically')}
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </Grid>
  );
};

const Facilities = ({
  allFacilities,
  othersFacilities,
  facilitiesNeedsAttention,
  totalRelays,
  inactiveRelays,
  order,
  changeOrder,
}) => {
  const { t } = useTranslation();
  const { isAdmin } = usePermissions();

  return (
    <Grid>
      <FacilitiesDashboard
        facilitiesNeedsAttention={facilitiesNeedsAttention}
        allFacilities={allFacilities}
        inactiveRelays={inactiveRelays}
        totalRelays={totalRelays}
      />
      {facilitiesNeedsAttention?.length === 0 &&
        othersFacilities?.length === 0 && <NoFacilitiesFound />}

      {facilitiesNeedsAttention?.length > 0 ? (
        <>
          <TitleSection
            title={t('needsAttention')}
            number={facilitiesNeedsAttention.length}
            sorting={<SortingInNeed order={order} changeOrder={changeOrder} />}
          />
          <FacilitiesList facilities={facilitiesNeedsAttention} />
        </>
      ) : null}
      {othersFacilities?.length > 0 ? (
        <>
          <TitleSection
            title={t('allOtherFacilities')}
            number={othersFacilities.length}
          />
          <FacilitiesList facilities={othersFacilities} />
        </>
      ) : null}

      {isAdmin() && <AddFacilityButton />}
    </Grid>
  );
};

Facilities.propTypes = {
  othersFacilities: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string.isRequired,
      location: propTypes.string,
      town: propTypes.string.isRequired,
      townData: propTypes.shape({
        townID: propTypes.oneOfType([propTypes.string, propTypes.number]),
        pcode: propTypes.string,
        englishName: propTypes.string,
        arabicName: propTypes.string,
      }),
    })
  ).isRequired,
  facilitiesNeedsAttention: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string.isRequired,
      location: propTypes.string,
      town: propTypes.string.isRequired,
      townData: propTypes.shape({
        townID: propTypes.oneOfType([propTypes.string, propTypes.number]),
        pcode: propTypes.string,
        englishName: propTypes.string,
        arabicName: propTypes.string,
      }),
    })
  ).isRequired,
  allFacilities: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string.isRequired,
      location: propTypes.string,
      town: propTypes.string.isRequired,
      townData: propTypes.shape({
        townID: propTypes.oneOfType([propTypes.string, propTypes.number]),
        pcode: propTypes.string,
        englishName: propTypes.string,
        arabicName: propTypes.string,
      }),
      devices: propTypes.arrayOf(
        propTypes.shape({
          type: propTypes.string.isRequired,
          deviceId: propTypes.string.isRequired,
          onlineConfirmationTimestamp: propTypes.string,
          lastCommunicationTimestamp: propTypes.string,
          status: propTypes.string.isRequired,
        })
      ),
    }).isRequired
  ).isRequired,
  totalRelays: propTypes.number.isRequired,
  inactiveRelays: propTypes.number.isRequired,
  order: propTypes.string.isRequired,
  changeOrder: propTypes.func.isRequired,
};

FacilitiesDashboard.propTypes = {
  facilitiesNeedsAttention: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string.isRequired,
      location: propTypes.string,
      town: propTypes.string.isRequired,
      townData: propTypes.shape({
        townID: propTypes.oneOfType([propTypes.string, propTypes.number]),
        pcode: propTypes.string,
        englishName: propTypes.string,
        arabicName: propTypes.string,
      }),
    })
  ).isRequired,
  allFacilities: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string.isRequired,
      location: propTypes.string,
      town: propTypes.string.isRequired,
      townData: propTypes.shape({
        townID: propTypes.oneOfType([propTypes.string, propTypes.number]),
        pcode: propTypes.string,
        englishName: propTypes.string,
        arabicName: propTypes.string,
      }),
      devices: propTypes.arrayOf(
        propTypes.shape({
          type: propTypes.string.isRequired,
          deviceId: propTypes.string.isRequired,
          onlineConfirmationTimestamp: propTypes.string,
          lastCommunicationTimestamp: propTypes.string,
          status: propTypes.string.isRequired,
        })
      ),
    }).isRequired
  ).isRequired,
  totalRelays: propTypes.number.isRequired,
  inactiveRelays: propTypes.number.isRequired,
};

FacilitiesList.propTypes = {
  facilities: propTypes.arrayOf(
    propTypes.shape({
      name: propTypes.string.isRequired,
      location: propTypes.string,
      town: propTypes.string.isRequired,
      townData: propTypes.shape({
        townID: propTypes.oneOfType([propTypes.string, propTypes.number]),
        pcode: propTypes.string,
        englishName: propTypes.string,
        arabicName: propTypes.string,
      }),
    })
  ).isRequired,
};

FacilityItem.propTypes = {
  facility: propTypes.shape({
    name: propTypes.string.isRequired,
    location: propTypes.string,
    town: propTypes.string.isRequired,
    townData: propTypes.shape({
      townID: propTypes.oneOfType([propTypes.string, propTypes.number]),
      pcode: propTypes.string,
      englishName: propTypes.string,
      arabicName: propTypes.string,
    }),
    lastTimeOnline: propTypes.number,
    offlineLabel: propTypes.string,
    devices: propTypes.arrayOf(
      propTypes.shape({
        type: propTypes.string.isRequired,
        deviceId: propTypes.string.isRequired,
        onlineConfirmationTimestamp: propTypes.string,
        lastCommunicationTimestamp: propTypes.string,
        status: propTypes.string.isRequired,
        notes: propTypes.arrayOf(
          propTypes.shape({
            technician: propTypes.string.isRequired,
            date: propTypes.string.isRequired,
            note: propTypes.string.isRequired,
          })
        ),
      })
    ),
  }).isRequired,
};

FacilityListItemInformation.propTypes = {
  name: propTypes.string.isRequired,
  /* eslint-disable-next-line */
  location: propTypes.string,
  needsAttention: propTypes.arrayOf(
    propTypes.shape({
      type: propTypes.string.isRequired,
      deviceId: propTypes.string.isRequired,
      onlineConfirmationTimestamp: propTypes.string,
      lastCommunicationTimestamp: propTypes.string,
      status: propTypes.string.isRequired,
      notes: propTypes.arrayOf(
        propTypes.shape({
          technician: propTypes.string.isRequired,
          date: propTypes.string.isRequired,
          note: propTypes.string.isRequired,
        })
      ),
    })
  ),
};

FacilityListItemInformation.defaultProps = {
  location: '',
  needsAttention: [],
};

SortingInNeed.propTypes = {
  order: propTypes.string.isRequired,
  changeOrder: propTypes.func.isRequired,
};

FacilityListItemIcons.propTypes = {
  offlineLabel: propTypes.string,
};

FacilityListItemIcons.defaultProps = {
  offlineLabel: null,
};

MutedText.propTypes = {
  text: propTypes.string.isRequired,
};

DaysInactive.propTypes = {
  text: propTypes.string.isRequired,
};

export default Facilities;
