import React, { useState, useContext } from 'react';
import { differenceInMinutes, parseISO } from 'date-fns';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGetDevicesByDirectorate } from '../../hooks';
import Facilities from './Facilities';
import NoFacilitiesFound from '../../components/NoFacilitiesFound/NoFacilitiesFound';
import { SearchFacilityContext } from '../../context/SearchFacilitiesContextProvider';
import { timeToWords, checkDeviceStatusInactive } from '../../utils/utils';
import LocaleContext from '../../locale/LocaleContext';

const FacilitiesPage = () => {
  const { t } = useTranslation();
  const { locale } = useContext(LocaleContext);
  const searchContext = useContext(SearchFacilityContext);
  const [sortingOrder, setSortingOrder] = useState('days');

  // eslint-disable-next-line
  let { facilities, loading, error } = useGetDevicesByDirectorate(
    // eslint-disable-next-line
    searchContext.directorate
  );

  const cachedDataKeyName = `facilities${String(
    // eslint-disable-next-line
    searchContext.directorate
  ).replace(' ', '')}`;

  if (loading) return <p style={{ margin: '16px' }}>{t('loading')}</p>;
  if (error) {
    // If the apollo has some error we try to get the
    // cached that for that directorate if available
    // otherwise, we just show the error
    facilities = JSON.parse(localStorage.getItem(`${cachedDataKeyName}`));
    if (!facilities) {
      return <p>{t('facilityPageNoConnection')}</p>;
    }
  } else {
    // Set data to the cache
    localStorage.setItem(`${cachedDataKeyName}`, JSON.stringify(facilities));
  }

  let filteredFacilities = facilities;

  // eslint-disable-next-line
  if (searchContext.query !== '' && facilities) {
    filteredFacilities = facilities.filter(
      (facility) =>
        // eslint-disable-next-line
        facility.name.toLowerCase().includes(searchContext.query) ||
        facility.devices?.some((device) =>
          // eslint-disable-next-line
          String(device.id).toLocaleLowerCase().includes(searchContext.query)
        )
    );
  }

  // Filtering facilities that have devices offline
  const facilitiesNeedsAttention = filteredFacilities.filter((facility) => {
    return facility.devices.some(checkDeviceStatusInactive);
  });

  // Removing facilities that have devices offline to create
  // anotehr list of "normal" facilities
  const othersFacilities = filteredFacilities.filter(
    (el) => !facilitiesNeedsAttention.includes(el)
  );

  const dateNow = parseISO(new Date().toISOString());

  const facilitiesThatNeedsAttentionWithDays = facilitiesNeedsAttention.map(
    (facility) => {
      let amountOfMinutes = 0;
      let auxAmount = 0;
      let offlineLabel = '';

      facility.devices.forEach((device) => {
        if (!device.active) {
          return;
        }
        auxAmount = differenceInMinutes(
          dateNow,
          parseISO(device.onlineConfirmationTimestamp)
        );
        if (auxAmount > amountOfMinutes) {
          amountOfMinutes = auxAmount;
          offlineLabel = timeToWords(
            device.onlineConfirmationTimestamp,
            locale
          );
        }
      });

      return {
        ...facility,
        lastTimeOnline: amountOfMinutes,
        offlineLabel,
      };
    }
  );

  if (sortingOrder === 'relays') {
    facilitiesThatNeedsAttentionWithDays.sort(
      (a, b) => b.devices.length - a.devices.length
    );
  } else if (sortingOrder === 'alpha') {
    facilitiesThatNeedsAttentionWithDays.sort((a, b) =>
      a.name.trim().localeCompare(b.name.trim())
    );
  } else {
    facilitiesThatNeedsAttentionWithDays.sort(
      (a, b) => b.lastTimeOnline - a.lastTimeOnline
    );
  }

  const totalRelays = filteredFacilities.reduce(
    (facilityCount, currentFacilitiy) =>
      facilityCount + currentFacilitiy.devices.length,
    0
  );

  // I'm sure there's a better way to do this
  const flattenedDevices = filteredFacilities.flatMap((facility) => [
    ...facility.devices,
  ]);

  const inactiveRelays = flattenedDevices.filter(
    checkDeviceStatusInactive
  ).length;

  return (
    <Grid>
      {!othersFacilities.length &&
      !facilitiesThatNeedsAttentionWithDays.length ? (
        <NoFacilitiesFound />
      ) : (
        <Facilities
          allFacilities={facilities}
          othersFacilities={othersFacilities}
          facilitiesNeedsAttention={facilitiesThatNeedsAttentionWithDays}
          totalRelays={totalRelays}
          inactiveRelays={inactiveRelays}
          order={sortingOrder}
          changeOrder={setSortingOrder}
        />
      )}
    </Grid>
  );
};

export default FacilitiesPage;
