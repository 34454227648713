import React from 'react';
import { Box, Link } from '@mui/material';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { useTranslation } from 'react-i18next';

import { StyledDrawerButton } from '../../styles/styles';

const FeedackButton = () => {
  const { t } = useTranslation();
  return (
    <Box>
      <StyledDrawerButton
        size="large"
        role="button"
        startIcon={<AnnouncementIcon />}
        aria-label="Give Feedback"
        sx={{ padding: '12px 16px 12px 16px', textDecoration: 'none' }}
      >
        <Link
          target="_blank"
          href="https://forms.gle/127VvQGHqHtwNJjVA"
          rel="noreferrer"
          sx={{
            textDecoration: 'none',
            width: '100%',
            textAlign: 'left',
            height: '100%',
          }}
        >
          {t('menu.feedback')}
        </Link>
      </StyledDrawerButton>
    </Box>
  );
};

export default FeedackButton;
