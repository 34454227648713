export const SENTRY_COLORS = {
  primary: {
    main: '#7FB3D5',
    dark: '#4377A1',
    light: '#A9DBF7',
    contrastText: '#FFFFFF',
    50: '#FEFFFF',
    100: '#E2F6FF',
    200: '#C4EAFE',
    300: '#A9DBF7',
    400: '#7FB3D5',
    500: '#6095BC',
    600: '#4377A1',
    700: '#1B4A79',
    800: '#053367',
    900: '#01285A',
  },
  secondary: {
    main: '#536063',
    dark: '#273134',
    light: '#AAB9BC',
    contrastText: '#FFFFFF',
    50: '#D5E3E4',
    100: '#BECDCF',
    200: '#AAB9BC',
    300: '#89999C',
    400: '#6F7F82',
    500: '#536063',
    600: '#3E4A4D',
    700: '#313C3F',
    800: '#273134',
    900: '#1F282B',
  },
};
