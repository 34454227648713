import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import { SearchFacilityContext } from '../context/SearchFacilitiesContextProvider';
import SAVE_DEVICE from '../mutations/saveDevice';
import GET_DEVICES_BY_DIRECTORATE from '../queries/getDevicesByDirectorate';
import { isProductionEnv } from '../utils/utils';

const useSaveDevice = () => {
  const { directorate } = useContext(SearchFacilityContext);
  const [saveDevice, { loading, error, data }] = useMutation(SAVE_DEVICE);
  const runSaveDevice = ({ device, facility, onlyForExperiments = false }) => {
    if (!facility) {
      // This is a workaround for seeding scripts. Do not remove (yet)!
      return saveDevice({
        variables: {
          device,
          // Do NOT save to sentry
          saveToSentry: false,
        },
      });
    }
    const { townData, ...facilityData } = facility;

    const refetchQueries = [
      {
        query: GET_DEVICES_BY_DIRECTORATE,
        variables: {
          directorate: facility.directorate,
        },
      },
    ];
    if (directorate && directorate !== facility.directorate) {
      refetchQueries.push({
        query: GET_DEVICES_BY_DIRECTORATE,
        variables: {
          directorate,
        },
      });
    }
    return saveDevice({
      variables: {
        device,
        facility: facilityData,
        saveToSentry: onlyForExperiments ? false : isProductionEnv(),
      },
      refetchQueries,
    });
  };
  return { runSaveDevice, response: { loading, error, data } };
};

export default useSaveDevice;
