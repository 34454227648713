import React, {
  useState,
  createContext,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import propTypes from 'prop-types';
// eslint-disable-next-line
import _ from 'lodash';
import { useLazyGetDevicesByDirectorate, useLazyGetTowns } from '../hooks';
import { AVAILABLE_DIRECTORATES_WITH_NONE } from '../const/const';
import { getFacilityFullLocation } from '../utils/models';

export const Store = createContext({});

export const useStore = () => {
  return useContext(Store);
};

const StoreProvider = ({ children }) => {
  const [facilitiesByDirectorate, setFacilities] = useState({});
  const [fetchTowns, { towns }] = useLazyGetTowns();
  /* @TODO fix db records throwing error (required deviceId missing) due to records having metadata as NONE */
  const [fetchDirectorateNone] = useLazyGetDevicesByDirectorate(
    AVAILABLE_DIRECTORATES_WITH_NONE[0].value
  );
  const [fetchDirectorate1] = useLazyGetDevicesByDirectorate(
    AVAILABLE_DIRECTORATES_WITH_NONE[1].value
  );
  const [fetchDirectorate2] = useLazyGetDevicesByDirectorate(
    AVAILABLE_DIRECTORATES_WITH_NONE[2].value
  );
  const [fetchDirectorate3] = useLazyGetDevicesByDirectorate(
    AVAILABLE_DIRECTORATES_WITH_NONE[3].value
  );
  const [fetchDirectorate4] = useLazyGetDevicesByDirectorate(
    AVAILABLE_DIRECTORATES_WITH_NONE[4].value
  );

  const [allFacilities, allDevices, allDevicesById] = useMemo(() => {
    const flatFacilities = [];
    let flatDevices = [];
    const devicesById = {};
    AVAILABLE_DIRECTORATES_WITH_NONE.forEach(({ value: directorate }) => {
      const facilities = facilitiesByDirectorate[directorate];
      if (!facilities) return;
      facilities.forEach((facility) => {
        const transformedFacility = {
          ...facility,
          directorate,
          value: getFacilityFullLocation(facility),
        };
        const isDirNone =
          directorate === AVAILABLE_DIRECTORATES_WITH_NONE[0].value;
        if (!isDirNone) {
          // Skip directorate "NONE", since it's for devices with no facility
          flatFacilities.push(transformedFacility);
        }
        flatDevices = flatDevices.concat(
          facility.devices.map((device) => {
            const newDevice = {
              ...device,
              // @TODO extract just the fields we want
              facility: isDirNone ? null : transformedFacility,
              value: device.deviceId,
            };
            devicesById[newDevice.deviceId] = newDevice;
            if (newDevice.altDeviceId) {
              devicesById[newDevice.altDeviceId] = newDevice;
            }
            return newDevice;
          })
        );
      });
    }, []);

    return [flatFacilities, flatDevices, devicesById];
  }, [facilitiesByDirectorate]);

  useEffect(() => {
    Promise.all([
      fetchDirectorateNone(),
      fetchDirectorate1(),
      fetchDirectorate2(),
      fetchDirectorate3(),
      fetchDirectorate4(),
      fetchTowns(),
    ])
      .then(
        ([resultDirNone, resultDir1, resultDir2, resultDir3, resultDir4]) => {
          setFacilities((currentValue) => {
            return {
              ...currentValue,
              [AVAILABLE_DIRECTORATES_WITH_NONE[0].value]:
                resultDirNone.data.getDevicesByDirectorate,
              [AVAILABLE_DIRECTORATES_WITH_NONE[1].value]:
                resultDir1.data.getDevicesByDirectorate,
              [AVAILABLE_DIRECTORATES_WITH_NONE[2].value]:
                resultDir2.data.getDevicesByDirectorate,
              [AVAILABLE_DIRECTORATES_WITH_NONE[3].value]:
                resultDir3.data.getDevicesByDirectorate,
              [AVAILABLE_DIRECTORATES_WITH_NONE[4].value]:
                resultDir4.data.getDevicesByDirectorate,
            };
          });
        }
      )
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const townsById = useMemo(() => {
    if (!towns) return;
    // eslint-disable-next-line
    return towns.reduce((byId, town) => {
      // eslint-disable-next-line
      byId[town.townID] = town;

      return byId;
    }, {});
  }, [towns]);

  const memoedValue = useMemo(() => {
    return {
      allFacilities,
      allDevices,
      allDevicesById,
      allTowns: towns || [],
      townsById,
      getFacilitiesByDirectorate: (directorate) =>
        facilitiesByDirectorate[directorate] || [],
      setFacilitiesByDirectorate: (directorate, facilities) => {
        setFacilities({
          ...facilitiesByDirectorate,
          [directorate]: facilities,
        });
      },
    };
  }, [
    facilitiesByDirectorate,
    allFacilities,
    allDevices,
    allDevicesById,
    towns,
    townsById,
  ]);

  return <Store.Provider value={memoedValue}>{children}</Store.Provider>;
};

StoreProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default StoreProvider;
