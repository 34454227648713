/* eslint-disable */
import React, { useState } from 'react';
import { Grid } from '@mui/material';
import AutocompleteInput from '../../components/AutocompleteInput/AutocompleteInput';
import { useStore } from '../../context/StoreProvider';
import { useSaveDevice, useUpdateDevicesLocation } from '../../hooks';
import {
  MAP_TOWN_LABEL_TO_ID,
  getDeviceRawData,
  getFacilityRawData,
} from './helper';
import usePermissions from '../../hooks/usePermissions';
import useSentryRelays from './useSentryRelays';

/* eslint-disable-next-line */
const fixTown_FromLabelToTownID = (
  updateDevicesLocation = () => Promise.reject(),
  dataSet = [],
  allTowns
) => {
  const facility = dataSet.shift();
  if (!facility) {
    console.log('--------------------- THE END ---------------------');
    return;
  }
  if (Number.isInteger(Number(facility.town))) {
    console.log('Already converted', facility);
    fixTown_FromLabelToTownID(updateDevicesLocation, dataSet, allTowns);
    return;
  }
  const townId =
    facility.townData?.townID ??
    MAP_TOWN_LABEL_TO_ID[facility.town] ??
    allTowns.find((town) => town.englishName === facility.town)?.townID;

  if (!townId) {
    console.log('No townId for the label in facility.town', facility);
    fixTown_FromLabelToTownID(updateDevicesLocation, dataSet, allTowns);
    return;
  }

  const payload = {
    onlyForExperiments: true,
    deviceIds: facility.devices.map(
      (device) => device.altDeviceId ?? device.deviceId
    ),
    facility: {
      name: facility.name,
      town: String(townId),
      location: facility.location,
      directorate: facility.directorate,
      whatsappLink: facility.whatsappLink,
    },
  };

  updateDevicesLocation(payload)
    .then(() => {
      console.log('SUCCESS >>>>>>');
      console.log(facility);
      console.log(payload);
      console.log('<<<<<<<<<<<<<<');
    })
    .catch((err) => {
      console.log('ERROR --------');
      console.log(err);
      console.log(facility);
      console.log(payload);
      console.log('--------------');
    })
    .finally(() => {
      fixTown_FromLabelToTownID(updateDevicesLocation, dataSet, allTowns);
    });
};

const fixDevices_FromSentryData = (
  saveDevice = () => Promise.reject(),
  toUpdate = []
) => {
  const payload = toUpdate.shift();
  if (!payload) {
    console.log('--------------------- THE END ---------------------');
    return;
  }
  const { ourDevice, sentryDevice } = payload;

  const ourDeviceData = getDeviceRawData(ourDevice);
  // { removeEmptyProperties: false, }
  const sentryDeviceData = getDeviceRawData(sentryDevice);
  const mergedDeviceData = { ...ourDeviceData, ...sentryDeviceData };
  const facilityData = getFacilityRawData(ourDevice.facility);
  console.log('Original device data:');
  console.log('- our: ', ourDevice);
  console.log('- sentry: ', sentryDevice);
  console.log('Raw device data:');
  console.log('- our:', ourDeviceData);
  console.log('- sentry: ', sentryDeviceData);
  console.log('To update ---');
  console.log('- merged device: ', mergedDeviceData);
  console.log('- facility data: ', facilityData);
  console.log('-------------');

  const newPayload = { onlyForExperiments: true, device: mergedDeviceData };
  if (ourDevice.facility) {
    newPayload.facility = facilityData;
  }

  // saveDevice({ device: mergedDeviceData, facility: facilityData })
  saveDevice(newPayload)
    .then(() => {
      console.log('SUCCESS >>>>>>');
    })
    .catch((err) => {
      console.log('ERROR x x x x x x');
      console.log(err);
      console.log('x x x x x x x x x');
    })
    .finally(() => {
      fixDevices_FromSentryData(saveDevice, toUpdate);
    });
};

const ScriptsPage = () => {
  const { allFacilities, allDevices, allDevicesById, allTowns } = useStore();
  const [facilityValue, setFacilityValue] = useState(null);
  const [deviceValue, setDeviceValue] = useState(null);

  const { isAdmin } = usePermissions();
  const { runSaveDevice } = useSaveDevice();
  const { runUpdateDevicesLocation } = useUpdateDevicesLocation();
  const { sentryRelays } = useSentryRelays();
  if (!isAdmin()) return navigate('/');

  console.log(
    !sentryRelays ? 'Sentry relays: loading' : 'Sentry relays: LOADED!'
  );

  const onFixTown = () => {
    // fixTown_FromLabelToTownID(runUpdateDevicesLocation, allFacilities);
    // const withData = allFacilities.filter((town) => Boolean(town.townData));
    const noData = allFacilities.filter((town) => !town.townData);
    console.log(noData, allFacilities.length);
    fixTown_FromLabelToTownID(runUpdateDevicesLocation, noData, allTowns);
  };

  const onFixAltDeviceId = () => {
    const withDifferentId = allDevices.filter(
      ({ deviceId, altDeviceId }) => altDeviceId && deviceId !== altDeviceId
    );
    console.log(withDifferentId.length, withDifferentId);
    console.log('NODE_ENV', process.env.NODE_ENV);
  };

  const onFixFromSentryData = (checkDataOnly) => {
    const missingInVws = [];
    const toUpdate = [];
    const idsIssue = [];
    const differentIdsNonIssue = [];
    (sentryRelays || []).forEach((device) => {
      const ourDevice =
        allDevicesById[device.id] || allDevicesById[device.serial];

      if (
        ourDevice &&
        ourDevice?.altDeviceId &&
        ourDevice?.altDeviceId !== device.id
      ) {
        idsIssue.push({ ourDevice, sentryDevice: device });
      } else if (
        ourDevice?.altDeviceId === device.id &&
        ourDevice?.altDeviceId !== ourDevice?.deviceId
      ) {
        differentIdsNonIssue.push({ ourDevice, sentryDevice: device });
      }

      if (!ourDevice) {
        missingInVws.push(device);
        // } else if (ourDevice.active === null || ourDevice.active === undefined) {
      } else if (ourDevice.active === null || ourDevice.active === undefined) {
        toUpdate.push({ ourDevice, sentryDevice: device });
      }
    });
    console.log('Missing in VWS:', missingInVws);
    console.log('To update to VWS: ', toUpdate);
    console.log('ids issue: ', idsIssue);
    console.log('diff ids to compare', differentIdsNonIssue);
    if (!checkDataOnly) {
      fixDevices_FromSentryData(runSaveDevice, toUpdate);
    }
  };

  return (
    <Grid padding={4}>
      <Grid marginBottom={4} display="flex" justifyContent="space-between">
        <button onClick={onFixTown} type="button">
          Fix town (town will be townID)
        </button>

        <button onClick={onFixAltDeviceId} type="button">
          Check deviceId != altDeviceId
        </button>

        <button onClick={() => onFixFromSentryData(true)} type="button">
          Check data from Sentry
        </button>
        <button onClick={() => onFixFromSentryData(false)} type="button">
          Pull from Sentry data to VWS
        </button>
      </Grid>

      <AutocompleteInput
        name="facility"
        label="Facilities"
        value={facilityValue}
        options={allFacilities}
        allowNewOption
        onChange={(e, newValue) => {
          // eslint-disable-next-line
          console.log('callback', newValue);
          setFacilityValue(newValue);
        }}
        groupBy={(facility) => facility.directorate}
      />
      <AutocompleteInput
        name="deviceId"
        label="Serial Number"
        value={deviceValue}
        options={allDevices}
        allowNewOption
        onChange={(e, newValue) => {
          // eslint-disable-next-line
          console.log('callback', newValue);
          setDeviceValue(newValue);
        }}
        groupBy={(device) => device.facility?.value}
      />
    </Grid>
  );
};

export default ScriptsPage;
