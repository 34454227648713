import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import { useTimezone } from '@hala-systems/react-timezone-provider';
import { Timezone } from './Timezone';

const TIMEZONES = [
  'America/Los_Angeles',
  'America/New_York',
  'Europe/London',
  'Europe/Lisbon',
  'Europe/Berlin',
  'Asia/Aden',
  'Asia/Damascus',
  'Asia/Riyadh',
];

export const TimezoneSelect = ({
  timezones = TIMEZONES,
  contextDate,
  label = 'Timezone',
}) => {
  const { timezone, setTimezone } = useTimezone();

  const handleChange = ({ target: { value } }) => {
    setTimezone(value === 'browser' ? null : value);
  };

  const options = [
    <MenuItem key={'browser'} value={'browser'}>
      <Timezone tz={'browser'} contextDate={contextDate} />
    </MenuItem>,
    ...timezones.map((tz) => (
      <MenuItem key={tz} value={tz}>
        <Timezone tz={tz} contextDate={contextDate} />
      </MenuItem>
    )),
  ];

  return (
    <FormControl variant="standard" fullWidth={true}>
      <InputLabel id="timezone-select-label">{label}</InputLabel>
      <Select
        labelId="timezone-select-label"
        id="timezone-select"
        renderValue={(tz) => <Timezone tz={tz} contextDate={contextDate} />}
        value={timezone ?? 'browser'}
        onChange={handleChange}
      >
        {options}
      </Select>
    </FormControl>
  );
};

TimezoneSelect.propTypes = {
  /**
   * A list if timezones to display
   * [IANA Timezone Names](https://en.wikipedia.org/wiki/List_of_tz_database_time_zones)
   */
  timezones: PropTypes.arrayOf(PropTypes.string),
  /**
   * The select adds the offest to each option taking DST into account.
   *
   * It defaults to using the current date/time, but if this contextDate
   * is provided it will use that to generate the "current" offset.
   */
  contextDate: PropTypes.instanceOf(Date),
  /**
   * The value for the `InputLabel`.
   */
  label: PropTypes.string,
};
