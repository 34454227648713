import { gql } from '@apollo/client';

const GET_DIRECTORATES = gql`
  query GetDirectorates {
    getDirectorates {
      name
    }
  }
`;

export default GET_DIRECTORATES;
