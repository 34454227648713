import React from 'react';
import propTypes from 'prop-types';
import { Button, Grid, List, Typography } from '@mui/material';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StyledDivider, StyledListItem } from '../../styles/styles';
import useSetDeviceActiveStatus from '../../hooks/useSetDeviceActiveStatus';
import usePermissions from '../../hooks/usePermissions';
import useResponseSnackbar from '../../hooks/useResponseSnackbar';
import useConfirmDialog from '../../hooks/useConfirmDialog';
import useTestActivation from '../../hooks/useTestActivation';
import { isProductionEnv } from '../../utils/utils';

const ToolListItem = ({
  label,
  description,
  icon,
  onClick,
  loading = false,
  isAllowed = false,
}) => (
  <>
    <StyledListItem>
      <Grid container>
        <Grid item xs={5} alignItems="center">
          <Button
            variant="contained"
            color="primary"
            startIcon={
              loading ? (
                <CircularProgress color="inherit" size={14} thickness={5} />
              ) : (
                icon
              )
            }
            onClick={onClick}
            style={{
              justifyContent: 'flex-start',
              width: '100%',
              maxWidth: 300,
            }}
            disabled={!isAllowed || loading}
          >
            {label}
          </Button>
        </Grid>
        <Grid item xs={7} alignItems="center">
          <Typography pl="12px">{description}</Typography>
        </Grid>
      </Grid>
    </StyledListItem>
    <StyledDivider margin={0} />
  </>
);

ToolListItem.propTypes = {
  label: propTypes.string.isRequired,
  description: propTypes.string.isRequired,
  icon: propTypes.node.isRequired,
  onClick: propTypes.func.isRequired,
  loading: propTypes.bool,
  isAllowed: propTypes.bool,
};
ToolListItem.defaultProps = {
  loading: false,
  isAllowed: false,
};

const RelayTools = ({ relay, facility }) => {
  const { t } = useTranslation();
  const {
    setDeviceActiveStatus,
    response: { loading },
  } = useSetDeviceActiveStatus();
  const {
    runTestActivation,
    response: { loading: loadingTestActivation },
  } = useTestActivation();
  // const locationState = useLocation();
  const navigate = useNavigate();
  const { isAdmin } = usePermissions();
  const { showError, showSuccess } = useResponseSnackbar();
  const [confirmDialog, { showDialog }] = useConfirmDialog();

  const handleActivation = async (isConfirmed) => {
    if (!isConfirmed) return;
    try {
      const result = await setDeviceActiveStatus({
        deviceId: relay.altDeviceId ?? relay.deviceId,
        isActive: !relay.active,
      });
      const newData = { ...result.data.setDeviceActiveStatus };
      const formattedFacility = facility;
      showSuccess(newData.active ? 'Relay activated' : 'Relay deactivated');

      formattedFacility.devices.forEach((device, index) => {
        if (device.deviceId === result.data.setDeviceActiveStatus.deviceId) {
          formattedFacility.devices[index] = result.data.setDeviceActiveStatus;
        }
      });

      navigate('.', {
        state: {
          // ...locationState.state,
          relay: { ...result.data.setDeviceActiveStatus },
          facility: formattedFacility,
          updatedRelay: true,
        },
        replace: true,
      });
    } catch (err) {
      showError(
        relay.active ? 'Failed to deactivate relay' : 'Failed to activate relay'
      );
    }
  };

  const translations = relay.active
    ? {
        label: t('toolsTab.deactivate'),
        description: t('toolsTab.deactivateDescription'),
        dialogTitle: t('toolsTab.deactivateDialogTitle'),
        dialogText: t('toolsTab.deactivateDialogText'),
      }
    : {
        label: t('toolsTab.activate'),
        description: t('toolsTab.activateDescription'),
        dialogTitle: t('toolsTab.activateDialogTitle'),
        dialogText: t('toolsTab.activateDialogText'),
      };

  const showRelayEditPage = () => {
    navigate('/edit-relay', {
      state: { facility, relay },
    });
  };

  return (
    <>
      <List>
        <ToolListItem
          label={t('toolsTab.sendTestLabel')}
          description={t('toolsTab.sendTestDescription')}
          icon={<NotificationImportantIcon />}
          isAllowed={isProductionEnv() && !loadingTestActivation && isAdmin()}
          // eslint-disable-next-line
          onClick={() => {
            runTestActivation({ deviceId: relay.deviceId })
              .then(() => {
                showSuccess('Test alert was sent');
              })
              .catch(() => {
                showError('Error sending test alert');
              });
          }}
        />
        <ToolListItem
          label={translations.label}
          description={translations.description}
          icon={<RemoveCircleIcon />}
          loading={loading}
          isAllowed={isAdmin()}
          onClick={() =>
            showDialog({
              title: translations.dialogTitle,
              text: `${translations.dialogText} ${relay.deviceId} (${relay.type})`,
              callback: handleActivation,
              confirmLabel: translations.label,
            })
          }
        />

        <ToolListItem
          label={t('editRelay')}
          description={t('editRelayDescription')}
          icon={<EditIcon />}
          isAllowed={isAdmin()}
          onClick={showRelayEditPage}
        />
      </List>
      {confirmDialog}
    </>
  );
};

RelayTools.propTypes = {
  relay: propTypes.shape({
    deviceId: propTypes.string,
    altDeviceId: propTypes.string,
    active: propTypes.bool,
    type: propTypes.string,
    version: propTypes.string,
    batch: propTypes.string,
    hasPowerkit: propTypes.bool,
    activationTime: propTypes.string,
    owner: propTypes.string,
    operator: propTypes.string,
  }).isRequired,
  facility: propTypes.shape({
    id: propTypes.string,
    name: propTypes.string,
    location: propTypes.string,
    town: propTypes.string,
    whatsappLink: propTypes.string,
    townData: propTypes.shape({
      townID: propTypes.oneOfType([propTypes.string, propTypes.number]),
      pcode: propTypes.string,
      englishName: propTypes.string,
      arabicName: propTypes.string,
    }),
    devices: propTypes.arrayOf(
      propTypes.shape({
        deviceId: propTypes.string,
        active: propTypes.bool,
        type: propTypes.string,
        version: propTypes.string,
        batch: propTypes.string,
        hasPowerkit: propTypes.bool,
        activationTime: propTypes.string,
        owner: propTypes.string,
        operator: propTypes.string,
      })
    ),
  }).isRequired,
};

export default RelayTools;
