import { useLazyQuery, useQuery } from '@apollo/client';
import GET_TOWNS from '../queries/getTowns';

const useGetTowns = (searchTerm) => {
  const { data, loading, error } = useQuery(GET_TOWNS, {
    variables: {
      searchTerm,
    },
  });
  return {
    loading,
    error,
    towns: data?.getTowns,
  };
};

export const useLazyGetTowns = (searchTerm) => {
  const [fetchTowns, { called, data, loading, error }] = useLazyQuery(
    GET_TOWNS,
    {
      variables: {
        searchTerm,
      },
    }
  );
  return [
    fetchTowns,
    {
      called,
      loading,
      error,
      towns: data?.getTowns,
    },
  ];
};

export default useGetTowns;
