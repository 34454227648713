import React from 'react';
import propTypes from 'prop-types';
import { Box } from '@mui/material';
import ExitToApp from '@mui/icons-material/ExitToApp';
import { useTranslation } from 'react-i18next';

import { Auth, Hub } from 'aws-amplify';
import { StyledDrawerButton } from '../../styles/styles';

const logout = async () => {
  await Auth.signOut({ global: true });
  Hub.dispatch('UI Auth', {
    event: 'AuthStateChange',
    message: 'signedout',
  });
};

const LogoutButton = ({ variant }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <StyledDrawerButton
        size="large"
        onClick={logout}
        variant={variant}
        role="button"
        startIcon={<ExitToApp />}
        aria-label="sign out"
        sx={{ padding: '12px 16px 16px 16px' }}
      >
        {t('menu.singOut')}
      </StyledDrawerButton>
    </Box>
  );
};

LogoutButton.propTypes = {
  variant: propTypes.string,
};
LogoutButton.defaultProps = {
  variant: null,
};

export default LogoutButton;
