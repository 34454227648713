import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ConfirmDialog = ({
  open,
  title,
  text,
  confirmLabel,
  cancelLabel,
  onClick,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: 'text.primary' }}>
          {text}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClick(false)}>
          {cancelLabel || t('cancelButton')}
        </Button>
        <Button onClick={() => onClick(true)} autoFocus>
          {confirmLabel || t('confirmButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ConfirmDialog.propTypes = {
  open: propTypes.bool.isRequired,
  title: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  onClick: propTypes.func.isRequired,
  confirmLabel: propTypes.string,
  cancelLabel: propTypes.string,
};
ConfirmDialog.defaultProps = {
  confirmLabel: '',
  cancelLabel: '',
};

export default ConfirmDialog;
