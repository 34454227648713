// eslint-disable-next-line
import React, { useContext, useState } from 'react';
import { SearchFacilityContext } from '../context/SearchFacilitiesContextProvider';
import { isValidUrl } from '../utils/utils';
import { AVAILABLE_SECTORS } from '../const/const';

const useFacilityForm = (data) => {
  const searchContext = useContext(SearchFacilityContext);
  const [facilityForm, setFacilityForm] = useState({
    name: data?.name ?? '',
    town: data?.town ?? '',
    location: data?.location ?? AVAILABLE_SECTORS[0].value,
    directorate: data?.directorate ?? searchContext.directorate,
    whatsappLink: data?.whatsappLink ?? '',
    townData: data?.townData ?? null,
  });

  const isFacilityFormValid = () => {
    return (
      facilityForm.name &&
      facilityForm.town &&
      facilityForm.directorate &&
      ((facilityForm.whatsappLink && isValidUrl(facilityForm.whatsappLink)) ||
        !facilityForm.whatsappLink)
    );
  };

  return [facilityForm, setFacilityForm, isFacilityFormValid];
};

export default useFacilityForm;
