import { useMutation } from '@apollo/client';
import SEND_TEST_ACTIVATION from '../mutations/sendTestActivation';

const useTestActivation = () => {
  const [sendTestActivation, { loading, error, data }] =
    useMutation(SEND_TEST_ACTIVATION);
  const runTestActivation = ({ deviceId }) => {
    return sendTestActivation({
      variables: {
        deviceId,
        // saveToSentry: onlyForExperiments ? false : isProductionEnv(),
      },
    });
  };
  return { runTestActivation, response: { loading, error, data } };
};

export default useTestActivation;
