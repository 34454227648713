import React from 'react';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CancelBackButton = ({ text }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid item marginRight="16px">
      <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
        {text ?? t('cancelButton')}
      </Button>
    </Grid>
  );
};

CancelBackButton.propTypes = {
  text: propTypes.string,
};

CancelBackButton.defaultProps = {
  text: null,
};

export default CancelBackButton;
