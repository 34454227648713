import React from 'react';
import { Typography } from '@mui/material';
import { formatSeconds } from '../../../utils/formatting';

export const durationPlaceholder = '--:-- / --:--';

export const TimeIndicator = React.memo(({ position, duration }) => {
  const durationString = duration
    ? `${formatSeconds(Math.ceil(position))} / ${formatSeconds(
        Math.ceil(duration)
      )}`
    : durationPlaceholder;

  return (
    <Typography variant="overline" align="center" display="block">
      {durationString}
    </Typography>
  );
});
