import React, { useState } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { Grid, Tab, Tabs } from '@mui/material';
import Badge from '@mui/material/Badge';
import { useTranslation } from 'react-i18next';

import RelayTabPanel from './RelayTabPanel';
import RelayDetailsTable from './RelayDetailsTable';
import RelayDetailsNotes from './RelayDetailsNotes';
import RelayTools from './RelayTools';
import { ContactButton } from '../../components';

import { useGetNotesForDevice, useAddNoteForDevice } from '../../hooks';
import { getTownLabel } from '../../utils/models';
import { formatFullTimestamp } from '../../utils/utils';

const RelayDetailsPage = () => {
  const { t } = useTranslation();
  const locationState = useLocation();
  const [value, setValue] = useState(String(t('status')).toLowerCase());
  const { relay, facility } = locationState.state;
  const { notes, loading, error } = useGetNotesForDevice(relay.deviceId);
  const { runAddNoteForDevice } = useAddNoteForDevice();
  const showNewFeature = Date.now() <= new Date('2022-12-31 23:59:59');

  let sortedNotes = [];
  if (!loading) {
    if (!error) {
      sortedNotes = [...notes];
      sortedNotes.sort((a, b) => {
        return new Date(b.timestamp) - new Date(a.timestamp);
      });
    }
  }

  if (!locationState.state) {
    return <Navigate to="/" />;
  }

  const lastActiveTime = formatFullTimestamp(
    relay?.onlineConfirmationTimestamp
  );
  const lastActivationTime = formatFullTimestamp(
    relay?.lastActivationTimestamp
  );

  const relayStatus = {
    status: relay.status,
    'serial number': relay.deviceId,
    location: facility.name,
    town: getTownLabel(facility.townData),
    'last active': lastActiveTime,
    'last activation': lastActivationTime,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const contactButton = <ContactButton facilityUrl={facility.whatsappLink} />;

  return (
    <Grid>
      <Tabs
        value={value}
        onChange={handleChange}
        centered
        variant="fullWidth"
        sx={{ borderBottom: '1px solid #e0e0e0' }}
      >
        <Tab
          label={t('status')}
          value={String(t('status')).toLowerCase()}
          sx={{ color: '#757575' }}
        />
        <Tab
          label={
            <Grid container justifyContent="center">
              <Grid item>
                {t('tools')}
                {showNewFeature && (
                  <Badge
                    badgeContent="New"
                    color="primary"
                    sx={{ position: 'absolute', right: '24px', mt: '8px' }}
                  />
                )}
              </Grid>
            </Grid>
          }
          value={String(t('tools')).toLowerCase()}
          sx={{ color: '#757575' }}
        />
        <Tab
          label={t('notes')}
          value={String(t('notes')).toLowerCase()}
          sx={{ color: '#757575' }}
        />
      </Tabs>
      <RelayTabPanel value={value} index={String(t('status')).toLowerCase()}>
        <RelayDetailsTable relay={relayStatus} />
        {contactButton}
      </RelayTabPanel>
      <RelayTabPanel value={value} index={String(t('tools')).toLowerCase()}>
        <RelayTools relay={relay} facility={facility} />
        {contactButton}
      </RelayTabPanel>
      <RelayTabPanel value={value} index={String(t('notes')).toLowerCase()}>
        <RelayDetailsNotes
          deviceId={relay.deviceId}
          notes={sortedNotes}
          saveNote={runAddNoteForDevice}
          connectionError={error}
        />
      </RelayTabPanel>
    </Grid>
  );
};

export default RelayDetailsPage;
