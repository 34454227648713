export const getTownLabel = (townData, isArabic = false) => {
  const townNameProp = isArabic ? 'arabicName' : 'englishName';
  return townData && townData[townNameProp]
    ? `${townData[townNameProp]} - ${townData.pcode}`
    : '';
};

export const getFacilityFullLocation = (facility) => {
  let fullLocation = facility.name ?? '';
  let extraInfo = getTownLabel(facility.townData);
  if (facility.location) {
    extraInfo = !extraInfo
      ? facility.location
      : `${extraInfo}, ${facility.location}`;
  }
  if (extraInfo) {
    fullLocation += ` (${extraInfo})`;
  }
  return fullLocation;
};

export default {
  getFacilityFullLocation,
};
