import React from 'react';
import propTypes from 'prop-types';
import { Authenticator, Heading } from '@aws-amplify/ui-react';
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css';
import { initAmplify } from '../../services/amplify';
import '../assets/amplify.css';

initAmplify();

const components = {
  Header() {
    return (
      <Heading marginBottom="2rem" level={1} textAlign="center">
        VWS Monitoring
      </Heading>
    );
  },
};

const VwsMonitoringAuthenticator = ({ children }) => (
  <Authenticator hideSignUp components={components}>
    {() => children}
  </Authenticator>
);

VwsMonitoringAuthenticator.propTypes = {
  children: propTypes.node.isRequired,
};

export default VwsMonitoringAuthenticator;
