import { SENTRY_COLORS } from '../colors';
import { BASE_THEME } from './base';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const SENTRY_THEME = {
  ...BASE_THEME,
  palette: {
    ...SENTRY_COLORS,
    mode: 'light',
  },
};

const SENTRY_THEME_CONFIG = createTheme(SENTRY_THEME);
export const SENTRY_THEME_RESPONSIVE = responsiveFontSizes(SENTRY_THEME_CONFIG);
