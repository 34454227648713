import React from 'react';
import PropTypes from 'prop-types';
import { ImageCarouselStepper } from './ImageCarouselStepper';
import { ImgCarouselWrapper, StyledBox } from './ImageCarouselStyles';
import { ImageWithFallback } from '../../components/helpers';
import { ImageCarouselDialog } from './ImageCarouselDialog';

export const ImageCarousel = ({ images }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <ImgCarouselWrapper data-testid="carousel">
      <StyledBox>
        <ImageWithFallback
          src={images[activeStep].imgPath}
          label={images[activeStep].label}
          onClick={handleClickOpen}
        />
      </StyledBox>
      <ImageCarouselStepper
        handleBack={handleBack}
        handleNext={handleNext}
        activeStep={activeStep}
        maxSteps={maxSteps}
      />
      <ImageCarouselDialog
        imgPath={images[activeStep].imgPath}
        label={images[activeStep].label}
        isOpen={open}
        setOpen={setOpen}
      />
    </ImgCarouselWrapper>
  );
};

ImageCarousel.propTypes = {
  // Array of objects containing image paths and labels
  images: PropTypes.array,
};
