/* eslint-disable */
import React, { useContext, useState } from 'react';
import propTypes from 'prop-types';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SearchFacilityContext } from '../../context/SearchFacilitiesContextProvider';
import { CancelBackButton, OverlayBackdrop } from '../../components';
import RelayForm from '../../components/RelayForm/RelayForm';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import { useSaveDevice, useRelayForm } from '../../hooks';
import { useStore } from '../../context/StoreProvider';
import useResponseSnackbar from '../../hooks/useResponseSnackbar';

import { DEVICE_BATCH_VERSION } from '../../const/const';

const RelayAddPage = ({ edit }) => {
  const { t } = useTranslation();
  const { runSaveDevice } = useSaveDevice();
  const { showError, showSuccess } = useResponseSnackbar();
  const { allFacilities } = useStore();
  const searchContext = useContext(SearchFacilityContext);
  const location = useLocation();
  const { facility, relay } = location.state;

  const [dialogConfirmationText, setDialogConfirmationText] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [dirtyFields, setDirtyFields] = useState({
    deviceId: false,
    type: false,
  });

  const [relayForm, setRelayForm, isRelayFormValid] = useRelayForm(relay);

  const navigate = useNavigate();
  if (!location.state) {
    return <Navigate to="/" />;
  }

  const handleDirtyFields = (newData) => {
    let newDirtyValues = Object.keys(newData).reduce(
      (values, fieldName) => {
        return {
          ...values,
          [fieldName]: !newData[fieldName],
        };
      },
      { ...dirtyFields }
    );
    newDirtyValues = { ...dirtyFields, ...newDirtyValues };
    setDirtyFields(newDirtyValues);
  };

  const handleFieldChange = (e, newData) => {
    const newValues = { ...relayForm, ...newData };
    const fields = Object.keys(newData);
    if (fields.includes('version')) {
      newValues.batch = newData.batch ?? DEVICE_BATCH_VERSION[newData.version];
    }
    setRelayForm(newValues);
    handleDirtyFields(newData);
  };

  const getFacilityData = () => {
    return {
      name: facility.name,
      // @TODO used to be sector, was dropped. could probably be removed with the respective backend change
      location: facility.location ?? '',
      town: facility.town,
      directorate: searchContext.directorate,
      whatsappLink: facility.whatsappLink,
      townData: facility.townData,
    };
  };

  const saveNewOrExistingDevice = () => {
    runSaveDevice({ device: relayForm, facility: getFacilityData() }, true)
      .then((response) => {
        showSuccess(t('successAddRelay'));
        setOpenBackdrop(false);
        navigate('/facility-details', {
          state: {
            facility: response.data.saveDevice,
          },
          replace: true,
        });
      })
      .catch(() => {
        setOpenBackdrop(false);
        showError(t('failAddRelay'));
      });
  };

  const confirmationBox = (option) => {
    setOpenConfirmation(false);
    if (option) {
      saveNewOrExistingDevice();
    } else {
      setOpenBackdrop(false);
    }
  };

  return (
    <Grid>
      <RelayForm
        relay={relayForm}
        dirtyFields={dirtyFields}
        onFieldChange={handleFieldChange}
        isEdit={edit}
      />

      <Grid
        container
        justifyContent="flex-end"
        paddingTop="16px"
        paddingRight="16px"
      >
        <CancelBackButton />

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={!isRelayFormValid()}
            onClick={() => {
              setOpenBackdrop(true);
              const facilityData = getFacilityData();
              const assignedRelay = allFacilities.filter((fData) =>
                fData?.devices.some(
                  (dData) => dData.deviceId === relayForm.deviceId
                )
              );
              if (!edit && assignedRelay.length) {
                setOpenConfirmation(true);
                setDialogConfirmationText(
                  t('relayAlreadyAssigned', {
                    originName: assignedRelay[0].name,
                    originTown: assignedRelay[0].townData?.englishName,
                    originSector: assignedRelay[0].location,
                    destName: facilityData.name,
                    destTown: facilityData.townData?.englishName,
                    destSector: facilityData.location,
                  })
                );
              } else {
                saveNewOrExistingDevice();
              }
            }}
          >
            {t('saveButton')}
          </Button>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={openConfirmation}
        onClick={confirmationBox}
        title="Relay already assigned"
        text={dialogConfirmationText}
      />
      <OverlayBackdrop open={openBackdrop} />
    </Grid>
  );
};

RelayAddPage.propTypes = {
  edit: propTypes.bool,
};

RelayAddPage.defaultProps = {
  edit: false,
};

export default RelayAddPage;
