import React, { useState, useContext, useCallback } from 'react';
import propTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
// eslint-disable-next-line
import _ from 'lodash';
import { AppBar, InputBase, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Search from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';

import { SearchFacilityContext } from '../../context/SearchFacilitiesContextProvider';

const MenuIconButton = ({ onMenuClick }) => (
  <IconButton
    size="large"
    edge="start"
    color="inherit"
    aria-label="open menu"
    onClick={onMenuClick}
    sx={{ paddingLeft: '20px' }}
  >
    <MenuIcon />
  </IconButton>
);

const BackIconButton = ({ onBackClick }) => (
  <IconButton
    size="large"
    edge="start"
    color="inherit"
    aria-label="go back"
    onClick={onBackClick}
    sx={{ paddingLeft: '20px' }}
  >
    <ChevronLeftIcon />
  </IconButton>
);

const SearchIconButton = ({ openSearch, isMainPage }) => (
  <IconButton
    size="large"
    edge="start"
    color="inherit"
    aria-label="open search"
    sx={{ margin: 0, visibility: isMainPage ? 'visible' : 'hidden' }}
    onClick={openSearch}
  >
    <Search />
  </IconButton>
);

const CloseSearchButton = ({ closeSearch }) => (
  <IconButton
    size="large"
    edge="start"
    color="inherit"
    aria-label="close search"
    sx={{ paddingLeft: '20px' }}
    onClick={closeSearch}
  >
    <CloseIcon />
  </IconButton>
);

const TopToolBar = ({ onMenuClick }) => {
  const { t } = useTranslation();
  const searchContext = useContext(SearchFacilityContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [openSearch, setOpenSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const openSearchField = () => {
    setOpenSearch(true);
  };

  const closeSearchField = () => {
    setOpenSearch(false);
    setSearchQuery('');
    // eslint-disable-next-line
    searchContext.searchHandler('');
  };

  // eslint-disable-next-line
  const delayedQuery = useCallback(
    // eslint-disable-next-line
    _.debounce((q) => searchContext.searchHandler(q), 300),
    []
  );

  const searchQueryHandler = (text) => {
    setSearchQuery(text);
    delayedQuery(text);
  };

  const isMainPage =
    !location.state && location.pathname !== '/add-facility-relay';
  let appbarTitle = t(
    // eslint-disable-next-line
    `menu.${String(searchContext.directorate).toLowerCase()}`
  );

  if (!isMainPage) {
    if (location.pathname === '/add-facility-relay') {
      appbarTitle = t('addLocationAndRelay');
    } else if (
      (location.pathname === '/relay-details' ||
        location.pathname === '/edit-relay') &&
      location?.state?.relay
    ) {
      appbarTitle = location.state.relay.type;
    } else if (location.state.facility) {
      appbarTitle =
        location.pathname === '/add-relay'
          ? `${t('addRelayTo')} ${location.state.facility.name}`
          : location.state.facility.name;
    } else {
      appbarTitle = location.state.relay.type;
    }
  }

  return (
    <AppBar position="fixed" sx={{ boxShadow: 0 }}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {openSearch === false || (openSearch && !isMainPage) ? (
          <>
            {isMainPage ? (
              <MenuIconButton onMenuClick={onMenuClick} />
            ) : (
              <BackIconButton
                onBackClick={() => {
                  if (location?.state?.updatedRelay) {
                    return navigate('/facility-details', {
                      state: {
                        relay: location?.state?.relay,
                        facility: location?.state?.facility,
                      },
                      replace: true,
                    });
                  }
                  return location.pathname === '/facility-details'
                    ? navigate('/')
                    : navigate(-1);
                }}
              />
            )}

            <Typography variant="h6" component="h1" noWrap>
              {appbarTitle}
            </Typography>
            <SearchIconButton
              isMainPage={isMainPage}
              openSearch={openSearchField}
            />
          </>
        ) : (
          <>
            <CloseSearchButton closeSearch={closeSearchField} />
            <InputBase
              autoFocus
              sx={{ ml: 1, flex: 1, color: '#ffffff' }}
              placeholder={t('search')}
              inputProps={{
                'aria-label': t('search'),
              }}
              value={searchQuery}
              onChange={(e) => searchQueryHandler(e.target.value)}
            />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

MenuIconButton.propTypes = {
  onMenuClick: propTypes.func.isRequired,
};

BackIconButton.propTypes = {
  onBackClick: propTypes.func.isRequired,
};

SearchIconButton.propTypes = {
  openSearch: propTypes.func.isRequired,
  isMainPage: propTypes.bool.isRequired,
};

CloseSearchButton.propTypes = {
  closeSearch: propTypes.func.isRequired,
};

TopToolBar.propTypes = {
  onMenuClick: propTypes.func.isRequired,
};

export default TopToolBar;
