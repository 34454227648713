import React from 'react';
import propTypes from 'prop-types';
import { Fab } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';
import { fabStyle } from '../../styles/styles';

const RelayDetailsAddNotes = ({ deviceId, note, closeModal, saveNote }) => {
  const { t } = useTranslation();
  return (
    <Fab
      variant="extended"
      sx={fabStyle}
      color="primary"
      onClick={async () => {
        await saveNote({ deviceId, note });
        closeModal();
      }}
      disabled={note.length < 3}
    >
      <SaveIcon sx={{ mr: 1 }} />
      {t('notesPage.save')}
    </Fab>
  );
};

RelayDetailsAddNotes.propTypes = {
  deviceId: propTypes.string.isRequired,
  note: propTypes.string.isRequired,
  closeModal: propTypes.func.isRequired,
  saveNote: propTypes.func.isRequired,
};

export default RelayDetailsAddNotes;
