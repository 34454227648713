import React from 'react';
import { Alert } from '@mui/material';
import { useSnackbar } from '../context/SnackbarProvider';

const useResponseSnackbar = () => {
  const { showSnackbar, hideSnackbar } = useSnackbar();

  const sharedOptions = {
    anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
    /* @TODO refactor this snackbar (hotfix)
      We should use theme colors instead of <Alerts>, or SnackbarProvider should be more customizable
    */
    ContentProps: {
      sx: {
        padding: 0,
        '& .MuiSnackbarContent-message': {
          padding: 0,
          flex: 1,
        },
        '& .MuiSnackbarContent-action': {
          position: 'absolute',
          right: '10px',
        },
      },
    },
  };

  const showSuccess = (message) => {
    showSnackbar(
      <Alert severity="success" variant="filled">
        {message}
      </Alert>,
      false,
      {
        ...sharedOptions,
        autoHideDuration: 3000,
        onClose: () => hideSnackbar(),
      }
    );
  };

  const showError = (message) => {
    showSnackbar(
      <Alert severity="error" variant="filled">
        {message}
      </Alert>,
      true,
      {
        ...sharedOptions,
        onClose: () => hideSnackbar(),
      }
    );
  };

  return {
    showSuccess,
    showError,
  };
};

export default useResponseSnackbar;
