import { useMutation } from '@apollo/client';
import ADD_NOTE_FOR_DEVICE from '../mutations/addNoteForDevice';
import GET_NOTES_FOR_DEVICE from '../queries/getNotesForDevice';

const UseAddNoteForDevice = () => {
  const [AddNoteForDevice, { loading, error, data }] =
    useMutation(ADD_NOTE_FOR_DEVICE);
  const runAddNoteForDevice = ({ deviceId, note }) => {
    AddNoteForDevice({
      variables: {
        deviceId,
        note,
      },
      refetchQueries: () => [
        {
          query: GET_NOTES_FOR_DEVICE,
          variables: {
            deviceId,
          },
        },
      ],
    });
  };
  return { runAddNoteForDevice, response: { loading, error, data } };
};

export default UseAddNoteForDevice;
