import React from 'react';
import { IconButton } from '@mui/material';

export const ExtraControl = ({
  action,
  Icon,
  label,
  buttonProps,
  iconProps,
}) => (
  <IconButton
    onClick={action}
    aria-label={label}
    label={label}
    {...buttonProps}
    size="large"
  >
    <Icon fontSize="small" {...iconProps} />
  </IconButton>
);
