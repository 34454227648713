import { ApolloClient, InMemoryCache } from '@apollo/client';
import { HttpLink } from 'apollo-link-http';
import { Auth } from 'aws-amplify';

const URI = process.env.REACT_APP_API_URI;

// Custom Fetch method to forward amplify's JWT token
export const customFetch = async (uri, options) => {
  const fetchOptions = { ...options };
  try {
    const session = await Auth.currentSession();
    const idToken = session?.idToken?.jwtToken;
    if (idToken) fetchOptions.headers.Authorization = idToken;
  } catch (e) {
    console.error({ error: e }); // eslint-disable-line no-console
  }

  return fetch(uri, fetchOptions);
};

const client = new ApolloClient({
  link: new HttpLink({
    uri: URI,
    fetch: customFetch,
  }),
  cache: new InMemoryCache(),
});

export default client;
