import React from 'react';
import propTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Box, Chip, Grid } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';
import { deviceChipColor } from '../../const/const';

const DeviceChip = ({ color }) => {
  const { t } = useTranslation();
  return (
    <Chip
      size="small"
      label={t(`relays.${color}`)}
      sx={{
        borderRadius: '2px',
        marginRight: 0.5,
        background: deviceChipColor[color].background,
        color: deviceChipColor[color].font,
      }}
    />
  );
};

const DevicesNeedAttention = ({ devices }) => (
  <Grid container mt={1}>
    <Box mr={0.5}>
      <ErrorOutlineIcon fontSize="small" sx={{ color: '#00000050' }} />
    </Box>
    {devices.map((device) => {
      return (
        <DeviceChip color={String(device.type).toLowerCase()} key={uuidv4()} />
      );
    })}
  </Grid>
);

DeviceChip.propTypes = {
  color: propTypes.string.isRequired,
};

DevicesNeedAttention.propTypes = {
  devices: propTypes.arrayOf(
    propTypes.shape({
      type: propTypes.string.isRequired,
    })
  ).isRequired,
};

export default DevicesNeedAttention;
