export const deviceChipColor = {
  'blue light': {
    background: '#2196F320',
    font: '#0C3C61',
    chipFont: '#0B79D0',
  },
  'red light': {
    background: '#F4433620',
    font: '#621B16',
    chipFont: '#F44336',
  },
  'yellow light': {
    background: '#FF980020',
    font: '#663D00',
    chipFont: '#FF9800',
  },
  buzzer: { background: '#F5F5F5', font: '#202020', chipFont: '#202020' },
  siren: { background: '#F5F5F5', font: '#202020', chipFont: '#202020' },
};

export const gettingAwayWithWarning = 'Hello there';

export const DEVICE_VERSION = [
  { value: 'v2.0', label: 'v2.0' },
  { value: 'v1.1', label: 'v1.1' },
  { value: 'v1.0', label: 'v1.0' },
  { value: '0.3', label: '0.3' },
];

export const DEVICE_BATCH = [
  { value: 'Batch 2019', label: 'Batch 2019' },
  { value: '1906-series', label: '1906-series' },
  { value: '5118-series', label: '5118-series' },
  { value: '2718-series', label: '2718-series' },
  { value: '4218-series', label: '4218-series' },
  { value: 'PIRELY-1', label: 'PIRELY-1' },
  { value: 'PIRELY-2', label: 'PIRELY-2' },
];

export const DEVICE_BATCH_VERSION = {
  'v2.0': 'Batch 2019',
  'v1.1': '1906-series',
  'v1.0': '2718-series',
  0.3: 'PIRELY-1',
};

export const DEVICE_TYPE = [
  { value: 'Blue light', label: 'Blue light' },
  { value: 'Yellow light', label: 'Yellow light' },
  { value: 'Red light', label: 'Red light' },
  { value: 'Siren', label: 'Siren' },
  { value: 'Buzzer', label: 'Buzzer' },
];

export const DEVICE_ACTIVATION_TIME = [
  { value: '24 hours', label: '24 hours' },
  { value: '12 hours/day', label: '12 hours/day' },
  { value: '12 hours/night', label: '12 hours/night' },
];

export const DEVICE_OWNER = [
  { value: 'SCD', label: 'SCD' },
  { value: 'IHD', label: 'IHD' },
  { value: 'AHD', label: 'AHD' },
];

export const DEVICE_OPERATOR = [
  { value: 'SCD Idlib', label: 'SCD Idlib' },
  { value: 'SCD Lattakia', label: 'SCD Lattakia' },
  { value: 'SCD Aleppo', label: 'SCD Aleppo' },
  { value: 'SCD Hama', label: 'SCD Hama' },
  { value: 'IMC', label: 'IMC' },
  { value: 'SAMS', label: 'SAMS' },
  { value: 'UOSSM', label: 'UOSSM' },
  { value: 'not supported', label: 'not supported' },
  { value: 'Shafak', label: 'Shafak' },
  { value: 'SEMA', label: 'SEMA' },
  { value: 'Watan', label: 'Watan' },
  { value: 'QCharity', label: 'QCharity' },
  { value: 'HiHFAD', label: 'HiHFAD' },
  { value: 'SIMRO', label: 'SIMRO' },
  { value: 'QRC', label: 'QRC' },
  { value: 'Medina', label: 'Medina' },
  { value: 'SDI', label: 'SDI' },
  { value: 'IHD', label: 'IHD' },
  { value: 'Hurras', label: 'Hurras' },
  { value: 'SRD', label: 'SRD' },
  { value: 'Ihsan', label: 'Ihsan' },
  { value: 'IDA', label: 'IDA' },
  { value: 'Women Now', label: 'Women Now' },
  { value: 'Syria Relief', label: 'Syria Relief' },
  { value: 'Takaful Al-sham', label: 'Takaful Al-sham' },
];

export const AVAILABLE_DIRECTORATES_WITH_NONE = [
  {
    value: 'NONE',
    label: 'None',
  },
  {
    value: '1st Directorate',
    label: '1st directorate',
  },
  {
    value: '2nd Directorate',
    label: '2nd directorate',
  },
  {
    value: '3rd Directorate',
    label: '3rd directorate',
  },
  {
    value: '4th Directorate',
    label: '4th directorate',
  },
];

export const AVAILABLE_DIRECTORATES = AVAILABLE_DIRECTORATES_WITH_NONE.slice(1);

export const AVAILABLE_SECTORS = [
  {
    value: '',
    label: '',
  },
  {
    value: '1st sector',
    label: '1st Sector',
  },
  {
    value: '2nd sector',
    label: '2nd Sector',
  },
  {
    value: '3rd sector',
    label: '3rd Sector',
  },
];
