import { Auth } from 'aws-amplify';

/**
 * Extract the permissions array from
 * the current user's idToken
 */
export const getUserPermissions = (sessionUser) => {
  const user = sessionUser ?? Auth.user;
  const permissions = user?.signInUserSession?.idToken?.payload?.permissions;
  return permissions ? permissions.split(',') : [];
};

export const userHasPermission = (user, permission) =>
  getUserPermissions(user).includes(permission);

export const getDirectorateFromUser = (user) => {
  const userPermissions = getUserPermissions(user);
  let directorate = '1st Directorate';
  if (userPermissions.includes('VWS_MON_1D')) {
    directorate = '1st Directorate';
  } else if (userPermissions.includes('VWS_MON_2D')) {
    directorate = '2nd Directorate';
  } else if (userPermissions.includes('VWS_MON_3D')) {
    directorate = '3rd Directorate';
  } else if (userPermissions.includes('VWS_MON_4D')) {
    directorate = '4th Directorate';
  }
  return directorate;
};
