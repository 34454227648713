import React, { useContext } from 'react';
import propTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { v4 as uuidv4 } from 'uuid';
import { Grid, Typography, List } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useTranslation } from 'react-i18next';
import { deviceChipColor } from '../../const/const';
import { StyledChip, StyledDivider, StyledListItem } from '../../styles/styles';
import LocaleContext from '../../locale/LocaleContext';
import {
  checkDeviceStatusDeactivated,
  checkDeviceStatusInactive,
} from '../../utils/utils';

const DeviceChip = ({ color }) => {
  const firstLetter = String(color).charAt(0).toUpperCase();

  return (
    <StyledChip
      label={firstLetter}
      background={deviceChipColor[color].background}
      font={deviceChipColor[color].chipFont}
    />
  );
};

const DevicesList = ({ devices, facility }) => {
  const { t } = useTranslation();
  const { locale } = useContext(LocaleContext);
  const navigate = useNavigate();

  const showRelayDetails = (relayData) => {
    navigate('/relay-details', {
      state: { relay: relayData, facility },
    });
  };

  return (
    <List>
      {devices.map((device) => (
        <React.Fragment key={uuidv4()}>
          <StyledListItem button onClick={() => showRelayDetails(device)}>
            <Grid container>
              <Grid item xs={2} alignItems="center">
                <DeviceChip color={String(device.type).toLowerCase()} />
              </Grid>
              <Grid item xs={8} pt={1}>
                <Typography>
                  {t(`relays.${String(device.type).toLowerCase()}`)}{' '}
                  {checkDeviceStatusInactive(device) && `(${t('notWorking')})`}
                  {checkDeviceStatusDeactivated(device) &&
                    `(${t('deactivated')})`}
                </Typography>
              </Grid>
              <Grid item xs={2} pt={1} textAlign="right">
                {locale === 'ar' ? (
                  <KeyboardArrowLeftIcon fontSize="small" color="disabled" />
                ) : (
                  <ChevronRightIcon fontSize="small" color="disabled" />
                )}
              </Grid>
            </Grid>
          </StyledListItem>
          <StyledDivider />
        </React.Fragment>
      ))}
    </List>
  );
};

DevicesList.propTypes = {
  devices: propTypes.arrayOf(
    propTypes.shape({
      type: propTypes.string.isRequired,
    })
  ).isRequired,
  facility: propTypes.shape({
    name: propTypes.string.isRequired,
    location: propTypes.string,
    town: propTypes.string.isRequired,
    whatsappLink: propTypes.string,
    townData: propTypes.shape({
      townID: propTypes.oneOfType([propTypes.string, propTypes.number]),
      pcode: propTypes.string,
      englishName: propTypes.string,
      arabicName: propTypes.string,
    }),
  }).isRequired,
};

DeviceChip.propTypes = {
  color: propTypes.string.isRequired,
};

export default DevicesList;
