import React from 'react';
import propTypes from 'prop-types';
import { Box } from '@mui/material';

const RelayTabPanel = ({ children, value, index }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
  >
    {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
  </div>
);

RelayTabPanel.propTypes = {
  children: propTypes.node.isRequired,
  value: propTypes.string.isRequired,
  index: propTypes.string.isRequired,
};

export default RelayTabPanel;
