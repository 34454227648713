import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Fab } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { fabStyle } from '../../styles/styles';

const ContactButton = ({ facilityUrl }) => {
  const { t } = useTranslation();
  return (
    <Fab
      variant="extended"
      sx={{ ...fabStyle }}
      color="primary"
      disabled={!facilityUrl}
      onClick={() => {
        if (facilityUrl) {
          window.open(facilityUrl);
        }
      }}
    >
      <WhatsAppIcon sx={{ mr: 1 }} />
      {t('contactButton')}
    </Fab>
  );
};

ContactButton.propTypes = {
  facilityUrl: propTypes.string,
};
ContactButton.defaultProps = {
  facilityUrl: null,
};

export default ContactButton;
