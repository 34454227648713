import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, TextField } from '@mui/material';
import { useHelperTextStyles } from '../../styles/styles';

const CustomTextInput = ({
  name,
  label,
  type,
  value,
  disabled,
  required,
  onChange,
  error,
  helperText,
}) => {
  const { t } = useTranslation();
  const helperTextStyles = useHelperTextStyles();

  let helperMsg = null;
  if (error) {
    if (helperText) {
      helperMsg = helperText;
    } else if (type === 'url') {
      helperMsg = t('invalidUrl');
    } else {
      helperMsg = t('invalidField');
    }
  }

  return (
    <Grid style={{ margin: '16px 16px 0px 16px' }}>
      <TextField
        name={name}
        label={label}
        type={type ?? 'text'}
        // defaultValue={value}
        value={value}
        disabled={disabled}
        required={required}
        onChange={onChange}
        error={error}
        helperText={helperMsg}
        fullWidth
        FormHelperTextProps={{
          classes: {
            root: helperTextStyles.noHorizontalMargin,
          },
        }}
      />
    </Grid>
  );
};

CustomTextInput.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  type: propTypes.string,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  disabled: propTypes.bool,
  required: propTypes.bool,
  onChange: propTypes.func.isRequired,
  error: propTypes.bool,
  helperText: propTypes.string,
};

CustomTextInput.defaultProps = {
  type: null,
  disabled: null,
  required: null,
  error: null,
  helperText: null,
};

export default CustomTextInput;
