import React, { useState, createContext } from 'react';
import propTypes from 'prop-types';
// eslint-disable-next-line
import _ from 'lodash';

export const SearchFacilityContext = createContext({
  query: '',
  searchHandler: _.noop,
});

const SearchFacilitiesContextProvider = ({ children }) => {
  const [query, setQuery] = useState('');
  const [directorate, setDirectorate] = useState('1st Directorate');

  const searchHandler = (searchQuery) => {
    setQuery(String(searchQuery).toLowerCase());
  };

  const directorateHandler = (dir) => {
    setDirectorate(dir);
  };

  return (
    <SearchFacilityContext.Provider
      // eslint-disable-next-line
      value={{
        query,
        searchHandler,
        directorate,
        directorateHandler,
      }}
    >
      {children}
    </SearchFacilityContext.Provider>
  );
};

SearchFacilitiesContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};

export default SearchFacilitiesContextProvider;
