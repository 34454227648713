import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../context/SnackbarProvider';
import useConnectionListener from './useConnectionListener';

const useConnectionSnackbar = () => {
  const { showSnackbar, hideSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const handleConnection = useCallback(
    (isOnline) => {
      const sharedOptions = {
        anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      };
      if (isOnline) {
        showSnackbar(t('Internet connection restored'), false, {
          ...sharedOptions,
          autoHideDuration: 3000,
          onClose: () => hideSnackbar(),
        });
      } else {
        showSnackbar(t('No internet connection'), true, {
          ...sharedOptions,
        });
      }
    },
    [showSnackbar, hideSnackbar, t]
  );
  useConnectionListener(handleConnection);
};

export default useConnectionSnackbar;
