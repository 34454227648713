import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export const ImageCarouselStepper = ({
  handleBack,
  handleNext,
  activeStep,
  maxSteps,
}) => {
  const theme = useTheme();

  const [NextArrow, BackArrow] =
    theme.direction === 'rtl'
      ? [KeyboardArrowLeft, KeyboardArrowRight]
      : [KeyboardArrowRight, KeyboardArrowLeft];

  return (
    <MobileStepper
      data-testid="stepper"
      steps={maxSteps}
      position="static"
      variant="text"
      activeStep={activeStep}
      nextButton={
        <Button
          size="small"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
        >
          Next
          <NextArrow />
        </Button>
      }
      backButton={
        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
          <BackArrow />
          Back
        </Button>
      }
    />
  );
};

ImageCarouselStepper.propTypes = {
  // Handles moving to the previous image
  handleBack: PropTypes.func.isRequired,
  // Handles moving to the next image
  handleNext: PropTypes.func.isRequired,
  // Current image
  activeStep: PropTypes.number.isRequired,
  // Total images
  maxSteps: PropTypes.number.isRequired,
};
