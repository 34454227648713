import React, { useState, useContext } from 'react';
import propTypes from 'prop-types';
import {
  AppBar,
  Toolbar,
  Dialog,
  TextField,
  IconButton,
  Slide,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import RelayDetailsAddNotes from './RelayDetailsAddNote';
import LocaleContext from '../../locale/LocaleContext';

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line
  return <Slide direction="up" ref={ref} {...props} />;
});

const WriteNotesDialog = ({ deviceId, isOpen, onClose, saveNote }) => {
  const { t } = useTranslation();
  const [noteText, setNoteText] = useState('');
  const { locale } = useContext(LocaleContext);

  const handleTyping = (event) => {
    setNoteText(event.target.value);
  };

  return (
    <Dialog
      dir={locale === 'ar' ? 'rtl' : 'ltr'}
      fullScreen
      open={isOpen}
      onClose={() => {
        setNoteText('');
        onClose();
      }}
      TransitionComponent={Transition}
    >
      <AppBar
        sx={{ position: 'relative', backgroundColor: '#ffffff', boxShadow: 0 }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            edge="start"
            onClick={() => {
              setNoteText('');
              onClose();
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <TextField
        id="standard-textarea"
        placeholder={t('notesPage.note')}
        multiline
        variant="standard"
        value={noteText}
        onChange={handleTyping}
        sx={{ pr: 2, pl: 2 }}
        InputProps={{ disableUnderline: true }}
      />

      <RelayDetailsAddNotes
        note={noteText}
        deviceId={deviceId}
        saveNote={saveNote}
        closeModal={() => {
          setNoteText('');
          onClose();
        }}
      />
    </Dialog>
  );
};

WriteNotesDialog.propTypes = {
  deviceId: propTypes.string.isRequired,
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  saveNote: propTypes.func.isRequired,
};

export default WriteNotesDialog;
