import { useLazyQuery, useQuery } from '@apollo/client';
import GET_DEVICES_BY_DIRECTORATE from '../queries/getDevicesByDirectorate';

const useGetDevicesByDirectorate = (directorate) => {
  const { data, loading, error } = useQuery(GET_DEVICES_BY_DIRECTORATE, {
    variables: {
      directorate,
    },
  });
  return {
    loading,
    error,
    facilities: data?.getDevicesByDirectorate,
  };
};

export const useLazyGetDevicesByDirectorate = (directorate) => {
  const [fetchDevices, { called, data, loading, error }] = useLazyQuery(
    GET_DEVICES_BY_DIRECTORATE,
    {
      variables: {
        directorate,
      },
    }
  );
  return [
    fetchDevices,
    {
      called,
      loading,
      error,
      facilities: data?.getDevicesByDirectorate,
    },
  ];
};

export default useGetDevicesByDirectorate;
