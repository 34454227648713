import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

export const ImgCarouselWrapper = styled('div')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
}));

export const StyledBox = styled((props) => <Box {...props} />)(({ theme }) => ({
  height: 195,
  overflow: 'hidden',
}));
