import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  CancelBackButton,
  FacilityForm,
  OverlayBackdrop,
  RelayForm,
} from '../../components';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import { useSaveDevice, useFacilityForm, useRelayForm } from '../../hooks';
import useResponseSnackbar from '../../hooks/useResponseSnackbar';
import usePermissions from '../../hooks/usePermissions';
import { useStore } from '../../context/StoreProvider';
import { DEVICE_BATCH_VERSION } from '../../const/const';

const AddFacilityRelayPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { runSaveDevice } = useSaveDevice();
  const { allFacilities } = useStore();
  const { showError, showSuccess } = useResponseSnackbar();
  const { isAdmin } = usePermissions();
  const [dialogConfirmationText, setDialogConfirmationText] = useState('');
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [dirtyFields, setDirtyFields] = useState({
    name: false,
    town: false,
    location: false,
    deviceId: false,
    type: false,
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [facilityForm, setFacilityForm, isFacilityFormValid] =
    useFacilityForm();
  const [relayForm, setRelayForm, isRelayFormValid] = useRelayForm();

  if (!isAdmin()) return navigate('/');

  const handleDirtyFields = (newData) => {
    let newDirtyValues = Object.keys(newData).reduce(
      (values, fieldName) => {
        return {
          ...values,
          [fieldName]: !newData[fieldName],
        };
      },
      { ...dirtyFields }
    );
    newDirtyValues = { ...dirtyFields, ...newDirtyValues };
    setDirtyFields(newDirtyValues);
  };

  const handleFacilityFieldChange = (e, newData) => {
    const newValues = { ...facilityForm, ...newData };
    setFacilityForm(newValues);
    handleDirtyFields(newData);
  };

  const handleRelayFieldChange = (e, newData) => {
    const newValues = { ...relayForm, ...newData };
    const fields = Object.keys(newData);
    if (fields.includes('version')) {
      newValues.batch = newData.batch ?? DEVICE_BATCH_VERSION[newData.version];
    }
    setRelayForm(newValues);
    handleDirtyFields(newData);
  };

  const isValidForm = () => {
    return isFacilityFormValid() && isRelayFormValid();
  };

  const saveNewOrExistingDevice = () => {
    runSaveDevice({
      device: relayForm,
      facility: facilityForm,
      shouldRefetch: true,
    })
      .then((response) => {
        if (!response.errors) {
          showSuccess(t('successAddFacilityRelay'));
          navigate('/');
        } else {
          showError(t('errorAddFacilityRelay'));
        }

        setOpenBackdrop(false);
      })
      .catch(() => {
        setOpenBackdrop(false);
        showError(t('errorAddFacilityRelay'));
      });
  };

  const confirmationBox = (option) => {
    setOpenConfirmation(false);
    if (option) {
      saveNewOrExistingDevice();
    } else {
      setOpenBackdrop(false);
    }
    setOpenBackdrop(false);
  };

  return (
    <Grid>
      <FacilityForm
        facility={facilityForm}
        dirtyFields={dirtyFields}
        onChange={handleFacilityFieldChange}
        addOnly
      />

      <Grid marginTop={2}>
        <RelayForm
          relay={relayForm}
          showTitle
          dirtyFields={dirtyFields}
          onFieldChange={handleRelayFieldChange}
        />
      </Grid>

      <Grid
        container
        justifyContent="flex-end"
        paddingTop="16px"
        paddingRight="16px"
      >
        <CancelBackButton />

        <Grid item mb={3}>
          <Button
            variant="contained"
            color="primary"
            disabled={!isValidForm()}
            onClick={() => {
              setOpenBackdrop(true);

              const assignedRelay = allFacilities.filter((fData) =>
                fData?.devices.some(
                  (dData) => dData.deviceId === relayForm.deviceId
                )
              );
              if (assignedRelay.length) {
                setOpenConfirmation(true);
                setDialogConfirmationText(
                  `This relay was already assigned to ${assignedRelay[0].name} (${assignedRelay[0].townData?.englishName} - ${assignedRelay[0].location}). Move it to ${facilityForm.name} (${facilityForm.townData?.englishName} - ${facilityForm.location}) instead?`
                );
              } else {
                saveNewOrExistingDevice();
              }
            }}
          >
            {t('saveButton')}
          </Button>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={openConfirmation}
        onClick={confirmationBox}
        title="Relay already assigned"
        text={dialogConfirmationText}
      />
      <OverlayBackdrop open={openBackdrop} />
    </Grid>
  );
};

export default AddFacilityRelayPage;
