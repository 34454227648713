import React from 'react';
import propTypes from 'prop-types';
import { InputLabel, Select, MenuItem, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AVAILABLE_DIRECTORATES } from '../../const/const';

const DirectorateDropdown = ({ value, onChange }) => {
  const { t } = useTranslation();

  return (
    <FormControl variant="standard" sx={{ m: '0px 16px' }}>
      <InputLabel id="select-directorate-label">
        {t('menu.directorate')}
      </InputLabel>
      <Select
        labelId="select-directorate-label"
        id="select-directorate"
        value={value}
        onChange={onChange}
        label={t('menu.directorate')}
      >
        {AVAILABLE_DIRECTORATES.map((directorate) => (
          <MenuItem key={directorate.value} value={directorate.value}>
            {t(`menu.${directorate.label}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

DirectorateDropdown.propTypes = {
  value: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
};

export default DirectorateDropdown;
