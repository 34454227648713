import React, { useState } from 'react';
import { useLocation, Navigate, useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  CancelBackButton,
  FacilityForm,
  OverlayBackdrop,
} from '../../components';
import { useUpdateDevicesLocation, useFacilityForm } from '../../hooks';
import useResponseSnackbar from '../../hooks/useResponseSnackbar';

const FacilityEditPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { facility, deviceIds } = location.state;
  const [dirtyFields, setDirtyFields] = useState({
    name: false,
    town: false,
    location: false,
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [facilityForm, setFacilityForm, isFacilityFormValid] =
    useFacilityForm(facility);
  const { runUpdateDevicesLocation } = useUpdateDevicesLocation();
  const { showError, showSuccess } = useResponseSnackbar();

  if (!location.state) {
    return <Navigate to="/" />;
  }

  const handleDirtyFields = (newData) => {
    let newDirtyValues = Object.keys(newData).reduce(
      (values, fieldName) => {
        return {
          ...values,
          [fieldName]: !newData[fieldName],
        };
      },
      { ...dirtyFields }
    );
    newDirtyValues = { ...dirtyFields, ...newDirtyValues };
    setDirtyFields(newDirtyValues);
  };

  const handleFieldChange = (e, newData) => {
    const newValues = { ...facilityForm, ...newData };
    setFacilityForm(newValues);
    handleDirtyFields(newData);
  };

  return (
    <Grid>
      <FacilityForm
        facility={facilityForm}
        dirtyFields={dirtyFields}
        onChange={handleFieldChange}
      />

      <Grid
        container
        justifyContent="flex-end"
        paddingTop="16px"
        paddingRight="16px"
      >
        <CancelBackButton />

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            disabled={!isFacilityFormValid()}
            onClick={() => {
              setOpenBackdrop(true);

              runUpdateDevicesLocation({ deviceIds, facility: facilityForm })
                .then((response) => {
                  showSuccess(t('successEditFacility'));
                  setOpenBackdrop(false);
                  navigate('/facility-details', {
                    state: {
                      facility: response.data.updateDevicesLocation,
                    },
                  });
                })
                .catch(() => {
                  setOpenBackdrop(false);
                  showError(t('failEditFacility'));
                });
            }}
          >
            {t('saveButton')}
          </Button>
        </Grid>
      </Grid>
      <OverlayBackdrop open={openBackdrop} />
    </Grid>
  );
};

export default FacilityEditPage;
