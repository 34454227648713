import React from 'react';
import propTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { Divider, Grid, Fab, List, ListItem, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { StyledChipSm, fabStyle } from '../../styles/styles';
import WriteNotesDialog from './WriteNotesDialog';

const TechnicianChip = ({ name }) => {
  const firstLetter = String(name).charAt(0).toUpperCase();

  return <StyledChipSm label={firstLetter} size="small" />;
};

const NoteCardTitle = ({ children }) => (
  <Typography component="span" color="#757575" fontWeight={600} mr="12px">
    {children}
  </Typography>
);

const NoteItem = ({ note }) => (
  <ListItem sx={{ padding: '16px' }}>
    <Grid container>
      <TechnicianChip name={note.technician ?? 'D'} />
      <Grid item ml="12px" xs={10}>
        <Grid item mb="10px">
          <NoteCardTitle>
            {note.technician ?? 'Default Technician'}
          </NoteCardTitle>
          <NoteCardTitle>{note.timestamp}</NoteCardTitle>
        </Grid>
        <Grid>{note.note}</Grid>
      </Grid>
    </Grid>
  </ListItem>
);

const RelayDetailsNotes = ({ deviceId, notes, saveNote, connectionError }) => {
  const { t } = useTranslation();
  const [openWriteNotes, setOpenWriteNotes] = React.useState(false);

  const handleClickOpenWriteNotes = () => {
    setOpenWriteNotes(true);
  };

  const handleCloseWriteNotes = () => {
    setOpenWriteNotes(false);
  };

  return (
    <>
      <List>
        {notes.length === 0 ? (
          <ListItem>
            {connectionError
              ? t('notesPageNoConnection')
              : t('notesPage.empty')}
          </ListItem>
        ) : (
          notes.map((note) => (
            <React.Fragment key={uuidv4()}>
              <NoteItem note={note} />
              <Divider />
            </React.Fragment>
          ))
        )}
      </List>

      <Fab
        variant="extended"
        sx={fabStyle}
        color="primary"
        onClick={handleClickOpenWriteNotes}
      >
        <AddIcon sx={{ mr: 1 }} />
        {t('notesPage.add')}
      </Fab>

      <WriteNotesDialog
        isOpen={openWriteNotes}
        onClose={handleCloseWriteNotes}
        deviceId={deviceId}
        saveNote={saveNote}
      />
    </>
  );
};

TechnicianChip.propTypes = {
  name: propTypes.string.isRequired,
};

NoteCardTitle.propTypes = {
  children: propTypes.node.isRequired,
};

NoteItem.propTypes = {
  note: propTypes.shape({
    technician: propTypes.string,
    timestamp: propTypes.string.isRequired,
    note: propTypes.string.isRequired,
  }).isRequired,
};

RelayDetailsNotes.propTypes = {
  deviceId: propTypes.string.isRequired,
  notes: propTypes.arrayOf(
    propTypes.shape({
      technician: propTypes.string,
      timestamp: propTypes.string.isRequired,
      note: propTypes.string.isRequired,
    })
  ),
  saveNote: propTypes.func.isRequired,
  connectionError: propTypes.bool,
};

RelayDetailsNotes.defaultProps = {
  notes: [],
  connectionError: false,
};

export default RelayDetailsNotes;
