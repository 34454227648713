import React, { useContext, useState } from 'react';
import propTypes from 'prop-types';
import { useAuthUser } from '@hala-systems/hala-auth';
import { Box, Divider, Grid, List, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import LanguageIcon from '@mui/icons-material/Language';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

import LogoutButton from '../LogoutButton/LogoutButton';
import FeedbackButton from '../FeedbackButton/FeedbackButton';
import DirectorateDropdown from './DirectorateDropdown';
import { ReactComponent as SentryLogo } from '../../assets/icons/logo.svg';
import {
  getDirectorateFromUser,
  userHasPermission,
} from '../../auth/permissions';

import { SearchFacilityContext } from '../../context/SearchFacilitiesContextProvider';
import LocaleContext from '../../locale/LocaleContext';
import { StyledDrawerDirectionButton } from '../../styles/styles';

const drawerWidth = 300;

const boxStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
};

const Drawer = styled(MuiDrawer)(() => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
  },
}));

const SideMenuTitle = () => {
  const { t } = useTranslation();
  return (
    <Typography component="h6" variant="h6" padding="0px 30px 28px 16px">
      {t('menu.vwsMonitoring')}
    </Typography>
  );
};
const SideMenuCloseButton = ({ onClick }) => (
  <IconButton
    aria-label="close sidebar"
    onClick={onClick}
    size="large"
    sx={{ p: 0 }}
  >
    <Close />
  </IconButton>
);

const Container = ({ children, open, onClose }) => (
  <Drawer variant="persistent" anchor="left" open={open} role="navigation">
    <Grid
      container
      direction="row"
      padding="32px 16px 28px 16px"
      alignItems="center"
    >
      <Grid item>
        <SentryLogo />
      </Grid>
      <Grid item sx={{ marginLeft: 'auto' }}>
        <SideMenuCloseButton onClick={onClose} />
      </Grid>
    </Grid>
    <Grid>
      <SideMenuTitle />
    </Grid>

    <Box sx={boxStyle}>{children}</Box>
  </Drawer>
);

const DirectionToggle = ({ lang, change }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <StyledDrawerDirectionButton
        dir={lang === 'ar' ? 'ltr' : 'rtl'}
        size="large"
        onClick={() => {
          change(lang === 'ar' ? 'en' : 'ar');
        }}
        role="button"
        startIcon={<LanguageIcon />}
        aria-label="change language"
        sx={{
          padding: '12px 16px 16px 16px',
        }}
      >
        {t('language')}
      </StyledDrawerDirectionButton>
    </Box>
  );
};

const Sidebar = ({ open, onClose }) => {
  const { locale } = useContext(LocaleContext);
  const { user } = useAuthUser();

  const isAdmin = userHasPermission(user, 'VWS_MON_ADMIN');
  const initialDirectorate = isAdmin
    ? '1st Directorate'
    : getDirectorateFromUser(user);

  const [directorate, setDirectorate] = useState(initialDirectorate);
  const searchContext = useContext(SearchFacilityContext);

  // If the user is not admin
  // we trigger the change of directorate so
  // the graphql will bring the right facilities and
  // will update the title
  if (!isAdmin) {
    // eslint-disable-next-line
    searchContext.directorateHandler(initialDirectorate);
  }

  const changeLocale = (l) => {
    if (locale !== l) {
      i18n.changeLanguage(l);
    }
  };

  const handleChangeDirectorate = (event) => {
    setDirectorate(event.target.value);
    // eslint-disable-next-line
    searchContext.directorateHandler(event.target.value);
    onClose();
  };

  return (
    <Container open={open} onClose={onClose}>
      {isAdmin ? (
        <>
          <DirectorateDropdown
            value={directorate}
            onChange={handleChangeDirectorate}
          />
          {/* <button
            type="button"
            onClick={() => {
              navigate('/add-partial-relay');
            }}
          >
            Add partial relay
          </button> */}
        </>
      ) : null}

      <List />
      <Box>
        <Divider />
        <DirectionToggle lang={locale} change={changeLocale} />
        <FeedbackButton />
        <LogoutButton />
      </Box>
    </Container>
  );
};

DirectionToggle.propTypes = {
  lang: propTypes.string,
  change: propTypes.func.isRequired,
};

DirectionToggle.defaultProps = {
  lang: '',
};

Sidebar.propTypes = {
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

SideMenuCloseButton.propTypes = {
  onClick: propTypes.func.isRequired,
};

Container.propTypes = {
  children: propTypes.node.isRequired,
  open: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
};

export default Sidebar;
