import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Fab } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { fabStyle } from '../../styles/styles';

const AddFacilityButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Fab
      variant="extended"
      sx={{ ...fabStyle }}
      color="primary"
      onClick={() => navigate('/add-facility-relay')}
    >
      <AddCircleIcon sx={{ mr: 1 }} />
      {t('addFacility')}
    </Fab>
  );
};

export default AddFacilityButton;
