import React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const NoFacilitiesFound = () => {
  const { t } = useTranslation();
  return (
    <Grid item padding={3}>
      {t('noFacilitiesFound')}
    </Grid>
  );
};

export default NoFacilitiesFound;
