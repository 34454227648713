import React from 'react';
import propTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const OverlayBackdrop = ({ open }) => (
  <Backdrop
    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={open}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

OverlayBackdrop.propTypes = {
  open: propTypes.bool.isRequired,
};

export default OverlayBackdrop;
