import React from 'react';
import propTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';

const TitleSection = ({
  title,
  number,
  sorting,
  marginTop,
  showNewFeature,
}) => (
  <Grid container backgroundColor="#FAFAFA" marginTop={marginTop}>
    <Typography
      variant="body2"
      fontWeight={600}
      sx={{ color: 'rgba(0, 0, 0, 0.87)', padding: '16px 6px 16px 16px' }}
    >
      {title}
    </Typography>
    <Typography
      variant="body2"
      fontWeight={600}
      sx={{ color: 'text.secondary', padding: '16px 0' }}
    >
      {number}
    </Typography>

    {showNewFeature && (
      <Grid
        sx={{ ml: 'auto', mr: '16px', display: 'flex', alignItems: 'center' }}
      >
        <Badge badgeContent="New" color="primary" sx={{ mr: '16px' }} />
      </Grid>
    )}
    {sorting}
  </Grid>
);

TitleSection.propTypes = {
  title: propTypes.string.isRequired,
  number: propTypes.number,
  sorting: propTypes.node,
  marginTop: propTypes.number,
  showNewFeature: propTypes.bool,
};

TitleSection.defaultProps = {
  number: null,
  sorting: null,
  marginTop: null,
  showNewFeature: false,
};

export default TitleSection;
