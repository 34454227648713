// eslint-disable-next-line
import React, { useState } from 'react';
import {
  DEVICE_ACTIVATION_TIME,
  DEVICE_OPERATOR,
  DEVICE_OWNER,
} from '../const/const';

const useRelayForm = (data) => {
  const [relayForm, setRelayForm] = useState({
    version: data?.version ?? 'v2.0',
    batch: data?.batch ?? 'Batch 2019',
    hasPowerkit: data?.hasPowerkit ?? true,
    deviceId: data?.deviceId ?? '',
    type: data?.type ?? '',
    activationTime: data?.activationTime ?? DEVICE_ACTIVATION_TIME[0].value,
    owner: data?.owner ?? DEVICE_OWNER[0].value,
    operator: data?.operator ?? DEVICE_OPERATOR[0].value,
  });

  const isRelayFormValid = () => {
    return (
      relayForm.version &&
      relayForm.batch &&
      relayForm.deviceId &&
      relayForm.type
    );
  };

  return [relayForm, setRelayForm, isRelayFormValid];
};

export default useRelayForm;
