import React from 'react';
import { Box, Grid, IconButton } from '@mui/material';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

import { getExtraControls } from './utils';

export const PlayerControls = ({
  isDisabled = false,
  isPlaying,
  onTogglePlay,
  onSkip,
  extraControls,
}) => {
  const PlayIcon = isPlaying ? PauseIcon : PlayArrowIcon;
  const playLabel = isPlaying ? 'Pause' : 'Play';
  const extraButtons = getExtraControls(extraControls);

  return (
    <Box flexGrow={1} display="flex" direction="row" width="100%">
      <Box display="flex" flexGrow={1} flexBasis={0} justifyContent="flex-end">
        {extraButtons.left && extraButtons.left.map((b) => b.button)}
      </Box>
      <Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <IconButton
            label="Skip Back"
            aria-label="Skip Back"
            onClick={() => onSkip(-1)}
            disabled={isDisabled}
            size="large"
            color="primary"
          >
            <SkipPreviousIcon fontSize="small" />
          </IconButton>
          <IconButton
            label={playLabel}
            aria-label={playLabel}
            onClick={onTogglePlay}
            disabled={isDisabled}
            color={isPlaying ? 'secondary' : 'primary'}
            size="large"
          >
            <PlayIcon fontSize="large" />
          </IconButton>
          <IconButton
            label="Skip Forward"
            aria-label="Skip Forward"
            onClick={() => onSkip(1)}
            disabled={isDisabled}
            size="large"
            color="primary"
          >
            <SkipNextIcon fontSize="small" />
          </IconButton>
        </Grid>
      </Box>

      <Box display="flex" flexGrow={1} flexBasis={0}>
        {extraButtons.right && extraButtons.right.map((b) => b.button)}
      </Box>
    </Box>
  );
};
