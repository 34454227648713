import { gql } from '@apollo/client';

const SET_DEVICE_ACTIVE_STATUS = gql`
  mutation SetDeviceActiveStatus($deviceId: String!, $isActive: Boolean!) {
    setDeviceActiveStatus(deviceId: $deviceId, isActive: $isActive) {
      id
      type
      deviceId
      status
      onlineConfirmationTimestamp
      active
      version
      batch
      hasPowerkit
      owner
      operator
      activationTime
    }
  }
`;

export default SET_DEVICE_ACTIVE_STATUS;
