import React from 'react';
import propTypes from 'prop-types';
import {
  Autocomplete,
  TextField,
  Grid,
  ListSubheader,
  autocompleteClasses,
  styled,
  Popper,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { VariableSizeList } from 'react-window';
import { useHelperTextStyles } from '../../styles/styles';

const LISTBOX_PADDING = 8; // px
const ITEM_SIZE = 36;
const GROUP_SIZE = 48;

const hasGroupProp = (target) =>
  Object.prototype.hasOwnProperty.call(target, 'group');

const renderRow = (props) => {
  const { data, index, style } = props;
  const currentRow = data[index];
  const inlineStyle = {
    ...style,
    top: Number(style.top) + LISTBOX_PADDING,
  };

  if (hasGroupProp(currentRow)) {
    return (
      <ListSubheader key={currentRow.key} component="div" style={inlineStyle}>
        {currentRow.group}
      </ListSubheader>
    );
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Typography component="li" {...currentRow[0]} noWrap style={inlineStyle}>
      {currentRow[1]}
    </Typography>
  );
};

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  // eslint-disable-next-line react/prop-types
  const { children, ...other } = props;
  const itemData = [];
  (children || []).forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const itemCount = itemData.length;

  const getChildSize = (child) =>
    hasGroupProp(child) ? GROUP_SIZE : ITEM_SIZE;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * ITEM_SIZE;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const AutocompleteVirtualized = ({
  name,
  label,
  options,
  value,
  onChange,
  groupBy,
  required,
  error,
  getOptionDisabled,
  getOptionLabel,
}) => {
  const { t } = useTranslation();
  const helperTextStyles = useHelperTextStyles();

  const getOptionLabelWrapper = (option) => {
    if (typeof option === 'string') {
      return option;
    }
    return getOptionLabel ? getOptionLabel(option) : option.value;
  };
  const isEqual = (option, newValue) =>
    option === newValue ||
    getOptionLabelWrapper(option) === getOptionLabelWrapper(newValue);

  return (
    <Grid style={{ margin: '16px 16px 0px 16px' }}>
      <Autocomplete
        disableListWrap
        ListboxComponent={ListboxComponent}
        PopperComponent={StyledPopper}
        getOptionDisabled={getOptionDisabled}
        autoHighlight
        autoComplete
        selectOnFocus
        clearOnBlur
        groupBy={groupBy}
        name={name}
        options={options}
        value={value || null} // empty string triggers a console warning
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          return getOptionLabel ? getOptionLabel(option) : option.value;
        }}
        isOptionEqualToValue={isEqual}
        onChange={(event, newValue) => {
          const matchingOption =
            typeof newValue === 'string' &&
            options.find((option) => isEqual(option, newValue));
          if (getOptionDisabled && matchingOption) {
            if (getOptionDisabled(matchingOption)) return;
          }
          let finalValue = newValue;
          // matching types (options and newValue), so it's easier to use
          const isOptionsString = typeof options[0] === 'string';
          if (newValue?.inputValue) {
            // new value (not in options) shown as `Add "${inputValue}`
            finalValue = isOptionsString
              ? newValue.inputValue
              : {
                  value: newValue.inputValue,
                };
          } else if (typeof newValue === 'string' && !isOptionsString) {
            finalValue = { value: finalValue };
          }
          onChange(event, matchingOption || finalValue);
        }}
        renderOption={(props, option) => [props, getOptionLabelWrapper(option)]}
        renderGroup={(params) => params}
        renderInput={(params) => (
          <TextField
            /* eslint-disable-next-line */
            {...params}
            label={label}
            name={name}
            required={required}
            error={error}
            helperText={error ? t('invalidField') : null}
            FormHelperTextProps={{
              classes: {
                root: helperTextStyles.noHorizontalMargin,
              },
            }}
          />
        )}
      />
    </Grid>
  );
};

AutocompleteVirtualized.propTypes = {
  name: propTypes.string.isRequired,
  label: propTypes.string.isRequired,
  onChange: propTypes.func.isRequired,
  groupBy: propTypes.func,
  required: propTypes.bool,
  error: propTypes.bool,
  getOptionDisabled: propTypes.func,
  getOptionLabel: propTypes.func,
  options: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.string),
    propTypes.arrayOf(
      propTypes.shape({
        value: propTypes.string,
      })
    ),
  ]).isRequired,
  value: propTypes.oneOfType([
    propTypes.string,
    propTypes.shape({
      value: propTypes.string,
    }),
  ]),
};
AutocompleteVirtualized.defaultProps = {
  value: null,
  groupBy: null,
  required: false,
  error: false,
  getOptionDisabled: null,
  getOptionLabel: null,
};

export default AutocompleteVirtualized;
